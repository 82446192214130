import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridApi, GridReadyEvent, RowDataChangedEvent, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { currencyFormatter, formatSeats, shortDateFormatter, shortEventTimeFormatter, shortGuidFormatter } from "../../utilities/Formatters";
import { ExchangeEnum } from "../../models/enums/ExchangeEnum";
import { OrderStatusEnum } from "../../models/enums/OrderStatusEnum";
import { IPurchaseResponse } from "../../models/IPurchaseResponse";
import { IPurchasesGridFilterState } from "./PurchasesFilter";
import { addDays, subMilliseconds } from "date-fns";

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "id", valueFormatter: shortGuidFormatter },
  {
    headerName: "Date",
    field: "createdAt",
    valueFormatter: shortDateFormatter,
  },
  {
    headerName: "Exchange",
    field: "exchange",
    valueFormatter: (params) => ExchangeEnum[params.value],
  },
  {
    headerName: "Total",
    field: "total",
    cellStyle: { textAlign: "right" },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: "Status",
    field: "status",
    valueFormatter: (params) => OrderStatusEnum[params.value],
  },
  {
    headerName: "Event",
    valueGetter: (params) => params.data.listing.event.name,
  },
  {
    headerName: "Event Date",
    valueGetter: (params) => params.data.listing.event.dateTime,
    valueFormatter: shortDateFormatter,
  },
  {
    headerName: "Event Time",
    valueGetter: (params) => params.data.listing.event.dateTime,
    valueFormatter: shortEventTimeFormatter,
  },
  {
    headerName: "Venue",
    valueGetter: (params) => params.data.listing.event.venueName,
  },
  {
    headerName: "Section",
    valueGetter: (params) => params.data.listing.section,
  },
  { headerName: "Row", valueGetter: (params) => params.data.listing.row },
  {
    headerName: "Seats",
    valueGetter: (params) => formatSeats(params.data.listing.startSeat, params.data.listing.endSeat),
  },
  //{ headerName: "Delivery", valueGetter: (params) => params.data.deliveryMethod.description },
  { headerName: "Internal Id", field: "posOrderId" },
];

export interface IPurchaseGridProps {
  rowData: IPurchaseResponse[] | undefined;
  filters: IPurchasesGridFilterState;
}

export function PurchasesGrid({ rowData, filters }: IPurchaseGridProps) {
  const { dateRange, textFilter, exchangeId } = filters;

  const [gridApi, setGridApi] = useState<GridApi>();

  function handleGridReady(e: GridReadyEvent) {
    setGridApi(e.api);
  }

  useEffect(() => {
    if (!rowData || !gridApi) {
      return;
    }

    var tempRowData = rowData
      .filter((r) => dateRange === undefined || dateRange[0] === null || r.createdAt >= dateRange[0])
      .filter((r) => dateRange === undefined || dateRange[1] === null || r.createdAt <= subMilliseconds(addDays(dateRange[1], 1), 1))
      .filter((r) => exchangeId === undefined || r.exchange === exchangeId);

    gridApi.setRowData(tempRowData);

    gridApi.setQuickFilter(textFilter);
  }, [dateRange, textFilter, exchangeId, gridApi, rowData]);

  function handleSelectionChanged(e: SelectionChangedEvent) {}

  function handleRowDataChanged(e: RowDataChangedEvent) {
    e.columnApi.autoSizeAllColumns(false);
  }

  gridApi?.setQuickFilter(textFilter);

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{ sortable: true, resizable: true }}
        suppressCellSelection={true}
        rowSelection="single"
        immutableData={true}
        getRowNodeId={(data) => data.id}
        onGridReady={handleGridReady}
        onSelectionChanged={handleSelectionChanged}
        onRowDataChanged={handleRowDataChanged}
        onFirstDataRendered={handleRowDataChanged}
      />
    </div>
  );
}
