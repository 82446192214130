import { Colors } from "@blueprintjs/core";
import React, {ImgHTMLAttributes} from "react";

export function SummaryItem(props: { title: string; value: number; iconProps: ImgHTMLAttributes<HTMLImageElement>; label: string }) {
  return (
    <div style={{ marginRight: "0px", padding: "60px 77px", textAlign: "left", height: "207px" }}>
      <div style={{ color: Colors.CERULEAN1, display: "flex" }}>
        <div style={{ paddingRight: "4px" }}><img {...props.iconProps} /></div>
        <div style={{ fontSize: "16px" }}>{props.title}</div>
      </div>
      <div style={{ fontSize: "32px", fontWeight: "bold", textAlign: "center" }}>{props.value}</div>
      <div style={{ color: Colors.GRAY1, textAlign: "center" }}>{props.label}</div>
    </div>
  );
}
