import { Icon, Button, FormGroup, Intent } from "@blueprintjs/core";
import { Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IExchangeIntegration } from "../../../../models/IIntegration";
import { addExchangeIntegration } from "../../../../slices/IntegrationsSlice";
import { PasswordInput, UsernameInput } from "../../../shared/InputGroups";
import { Popover2, Classes } from "@blueprintjs/popover2";

interface ApexFormValues {
  username: string;
  password: string;
}

function validateApex(values: ApexFormValues) {
  const errors: FormikErrors<ApexFormValues> = {};

  if (!values.username) {
    errors.username = "Required";
  } else {
    if (values.username.indexOf("@") >= 0) {
      errors.username = "API Username will not be an email address";
    }
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
}

export function ApexIntegrationMissing({ id, name }: IExchangeIntegration) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (params: ApexFormValues) => {
    setIsOpen(false);

    dispatch(addExchangeIntegration(id, JSON.stringify(params), "{}"));
  };

  return (
    <tr key={id}>
      <td>{name}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon="offline" />
      </td>
      <td style={{ textAlign: "center" }}>
        <Popover2
          content={
            <Formik
              initialValues={{
                username: "BrokerNerds_apiuser",
                password: "SB[-}xxT)-",
              }}
              //initialValues={{ username: "", password: "" }}
              //TODO: Revert before production
              onSubmit={onSubmit}
              validate={validateApex}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form>
                  <FormGroup
                    label="APEX API Username"
                    helperText={(touched.username && errors.username) ?? "Contact support@autoprocessor.com for your API credentials"}
                    labelInfo="(required)"
                    labelFor="apiUser-input"
                  >
                    <UsernameInput
                      id="apiUser-input"
                      placeholder="Username"
                      autoFocus={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      leftIcon="person"
                      value={values.username}
                      intent={touched.username && errors.username ? Intent.WARNING : Intent.NONE}
                      name="username"
                    />
                  </FormGroup>
                  <FormGroup
                    label="APEX API Password"
                    helperText={"Contact support@autoprocessor.com for your API credentials"}
                    labelInfo="(required)"
                    labelFor="apiPassword-input"
                  >
                    <PasswordInput
                      id="apiPassword-input"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      leftIcon="key"
                      value={values.password}
                      intent={touched.password && errors.password ? Intent.WARNING : Intent.NONE}
                      name="password"
                    />
                  </FormGroup>

                  <Button
                    intent={Intent.PRIMARY}
                    disabled={isSubmitting || !!errors.username || !!errors.username || !!(values.username.length < 1) || !!(values.password.length < 1)}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Connect
                  </Button>
                </form>
              )}
            </Formik>
          }
          popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Button minimal={true} onClick={() => setIsOpen(!isOpen)}>
            Connect
          </Button>
        </Popover2>
      </td>
    </tr>
  );
}
