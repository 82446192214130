import skyboxLogo from "../../images/skybox-logo.png";
import posnextLogo from "../../images/posnext-logo.jpg";
import ticketutilsLogo from "../../images/ticket-utils-logo.png";

interface IImageAttributes {
  src: string;
  alt: string;
}

export const SkyBox: IImageAttributes = {
  src: skyboxLogo,
  alt: "SkyBox by Vivid Seats",
};

export const PosNext: IImageAttributes = {
  src: posnextLogo,
  alt: "PosNext by TicketNetwork",
};

export const TicketUtils: IImageAttributes = {
  src: ticketutilsLogo,
  alt: "TicketUtils by StubHub",
};
