import React, { useEffect } from "react";
import { TopBar } from "./topbar/TopBar";
import { Route, Routes } from "react-router-dom";
import { EventPage } from "./event/EventPage";
import { SearchHome } from "./search/SearchHome";
import { SearchResults } from "./search/SearchResults";
import { ComponentWithParams } from "./shared/Routing";
import { ErrorBoundary } from "./ErrorBoundary";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "@blueprintjs/core";
import { useDispatch } from "react-redux";
import { fetchExchangeIntegrations, fetchPointOfSaleIntegration } from "../slices/IntegrationsSlice";
import { fetchSubscription } from "../slices/SubscriptionsSlice";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faShoppingCart, faCheck, faUserCheck, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { PurchasesPage } from "./purchases/PurchasesPage";
import { initUser, setAxiosTokenInterceptor } from "../nexusApi";
import { useMutation, useQuery } from "react-query";
import { fetchWallet } from "../slices/WalletSlice";

library.add(faShoppingCart, faCheck, faUserCheck, faCalendarCheck);

function App() {
  const { isLoading, isAuthenticated, error, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const dispatch = useDispatch();

  const { data: token, isLoading: isGettingToken } = useQuery("token", () => getAccessTokenSilently().then((res) => res));

  const [mutate] = useMutation(initUser);

  useEffect(() => {
    if (isGettingToken || isLoading || !isAuthenticated || error) {
      return;
    }

    setAxiosTokenInterceptor(token ?? "");
    mutate();
    dispatch(fetchSubscription());
    dispatch(fetchPointOfSaleIntegration());
    dispatch(fetchExchangeIntegrations());
    dispatch(fetchWallet());
  }, [dispatch, mutate, token, isGettingToken, isLoading, isAuthenticated, error]);

  if (error) {
    return <div>An error occurred with authorization provider... {error.message}</div>;
  }

  if (isLoading) {
    return <Spinner />; // TODO: Replace with better loading screen(?)
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  return (
    <div style={{ height: "100vh" }}>
      <ErrorBoundary>
        <TopBar />
        <Routes>
          <Route path="/*" element={<SearchHome />} />
          <Route path="/search/*" element={<SearchResults />} />
          <Route path="/event/:eventId/*" element={<ComponentWithParams>{(params: Record<string, string>) => <EventPage eventId={params.eventId} />}</ComponentWithParams>}></Route>
          <Route path="/purchases/*" element={<PurchasesPage />} />
          <Route
            path="/purchases/:purchaseId/*"
            element={<ComponentWithParams>{(params: Record<string, string>) => <PurchasesPage purchaseId={params.purchaseId} />}</ComponentWithParams>}
          ></Route>
        </Routes>
      </ErrorBoundary>
    </div>
  );
}

export default App;
