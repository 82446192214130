import { Button, Classes, ControlGroup, Dialog, HTMLSelect, Intent, NumericInput } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { IMonitor } from "../../models/IMonitor";
import { useMutation } from "react-query";
import { putMonitor } from "../../nexusApi";
import { INotificationFilters } from "../../models/INotificationFilters";
import { useState } from "react";

export function MonitorNotificationSettingsDialog({ monitor, isOpen, onClose, onSubmit }: { monitor?: IMonitor, isOpen: boolean, onClose: () => void, onSubmit: () => void }) {

  const [notificationType, setNotificationType] = useState("Never");
  const [notificationThreshold, setNotificationThreshold] = useState<number | undefined>();
  const [updateMonitor, { isLoading: isUpdatingMonitor }] = useMutation(putMonitor);

  useEffect(() => {
    let initialType = "Never";
    let initialThreshold: number | undefined;

    if (monitor === undefined)
      return;

    if (monitor.notificationJson !== "{}") {
      const notificationFilters = JSON.parse(monitor.notificationJson) as INotificationFilters;

      if (notificationFilters.priceAbove !== undefined) {
        initialType = "Price Above";
        initialThreshold = notificationFilters.priceAbove;
      } else if (notificationFilters.priceBelow !== undefined) {
        initialType = "Price Below";
        initialThreshold = notificationFilters.priceBelow;
      } else if (notificationFilters.numListingsBelow !== undefined) {
        initialType = "# Listings Below";
        initialThreshold = notificationFilters.numListingsBelow;
      } else if (notificationFilters.numTicketsBelow !== undefined) {
        initialType = "# Tickets Below";
        initialThreshold = notificationFilters.numTicketsBelow;
      }
    }

    setNotificationType(initialType);
    setNotificationThreshold(initialThreshold);
  }, [monitor])

  if (!monitor) {
    return null;
  }

  const handleDialogClose = () => {
    setNotificationType("Never");
    setNotificationThreshold(undefined);
    onClose();
  };

  const handleSubmit = () => {

    const newFilters: INotificationFilters = {};

    if (notificationType === "Price Below") {
      newFilters.priceBelow = notificationThreshold;
    } else if (notificationType === "Price Above") {
      newFilters.priceAbove = notificationThreshold;
    } else if (notificationType === "# Tickets Below") {
      newFilters.numTicketsBelow = notificationThreshold;
    } else if (notificationType === "# Listings Below") {
      newFilters.numListingsBelow = notificationThreshold;
    }

    let notificationJson = JSON.stringify(newFilters);

    const monitorToUpdate: IMonitor = {
      ...monitor,
      notificationJson,
    };

    updateMonitor(monitorToUpdate).then(handleDialogClose).then(onSubmit);
  }

  return (
    <Dialog isOpen={isOpen} onClose={() => handleDialogClose()} title="Notification Settings" icon="notifications">
      <div className={Classes.DIALOG_BODY}>
        <p>Notify me when...</p>
        <ControlGroup>
          <HTMLSelect
            options={["Never", "Price Below", "Price Above", "# Tickets Below", "# Listings Below"]}
            onChange={(e) => {
              setNotificationType(e.target.value);
              setNotificationThreshold(undefined);
            }}
            value={notificationType}
            disabled={isUpdatingMonitor}
          />
          <NumericInput
            value={notificationThreshold ?? ""}
            placeholder={""}
            onValueChange={(valueAsNumber) => setNotificationThreshold(valueAsNumber)}
            min={0}
            stepSize={1}
            majorStepSize={10}
            minorStepSize={1}
            leftIcon={notificationType === "Price Below" || notificationType === "Price Above" ? "dollar" : false}
            disabled={notificationType === "Never" || isUpdatingMonitor}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
          />
          <Button icon={"arrow-right"} onClick={handleSubmit} loading={isUpdatingMonitor} intent={Intent.PRIMARY} />
        </ControlGroup>
      </div>
    </Dialog>
  );
}