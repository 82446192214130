import React from "react";
import ReactDOM from "react-dom";
import { FocusStyleManager } from "@blueprintjs/core";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./components/App";
import "./css/index.css";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

FocusStyleManager.onlyShowFocusOnTabs();

const queryCache = new QueryCache();

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN ?? ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID ?? ""}
      audience={process.env.REACT_APP_AUTH_AUDIENCE ?? ""}
      redirectUri={window.location.origin}
    >
      <Provider store={store}>
        <ReactQueryCacheProvider queryCache={queryCache}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ReactQueryCacheProvider>
      </Provider>
    </Auth0Provider>,
    document.getElementById("root")
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./components/App", render);
}
