import { Alignment, Button, Classes, Dialog, FormGroup, Intent, Navbar, NumberRange } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import React, { useState, KeyboardEvent, useEffect } from "react";
import { ListingsFilterAdvanced } from "./ListingsFilterAdvanced";
import { PopoverTextInputButton } from "../shared/PopoverTextInputButton";
import { PriceRangeFilter } from "./PriceRangeFilter";
import { QuantityFilter } from "./QuantityFilter";
import { IExchangeId } from "../../models/IExchangeId";
import { EventLinkButtonGroup } from "./EventLinkButtonGroup";
import { ExchangeEnum } from "../../models/enums/ExchangeEnum";
import { IMonitor } from "../../models/IMonitor";
import { useMutation } from "react-query";
import { postMonitor } from "../../nexusApi";
import { v4 as uuidv4 } from "uuid";
import { addDays } from "date-fns";
import { IListingsFilters } from "./EventPage";
import { TextInputClearable } from "../shared/TextInputClearable";

export interface IEventPageNavBarProps {
  exchangeIds: IExchangeId[] | undefined;

  onListingsFilterChange(newValue: IListingsFilters):  void;

  onNewMonitor(monitor: IMonitor): void;

  filters: IListingsFilters;
  initialFilters: IListingsFilters;
  eventId: number;
  eventDate: Date;
}

const areas = `sections rows quantity price advanced clear`;

export function EventPageNavBar({ exchangeIds, onListingsFilterChange, onNewMonitor, eventId, eventDate, filters, initialFilters }: IEventPageNavBarProps) {
  const [addMonitor, { isLoading: isAddingMonitor }] = useMutation(postMonitor);
  const [isMonitorDialogOpen, setIsMonitorDialogOpen] = useState(false);
  const [monitorName, setMonitorName] = useState(GenerateMonitorName(filters));

  function handleSectionFilterChange(newValue: string) {
    const newState = {
      ...filters,
      sectionsFilter: newValue,
    };

    onListingsFilterChange(newState);
  }

  function handleRowFilterChange(newValue: string) {
    const newState = {
      ...filters,
      rowsFilter: newValue,
    };

    onListingsFilterChange(newState);
  }

  function handleQuantityFilterChange(newValues: number[]) {
    const newState = {
      ...filters,
      splitsFilter: newValues,
    };

    onListingsFilterChange(newState);
  }

  function handlePriceRangeFilterChange(newValues: NumberRange) {
    const newState = {
      ...filters,
      minPrice: newValues[0],
      maxPrice: newValues[1],
    };

    onListingsFilterChange(newState);
  }

  function handleExchangeFilterChange(newValues: number[]) {
    const newState = {
      ...filters,
      exchangesFilter: newValues,
    };

    onListingsFilterChange(newState);
  }

  function handleReset() {
    onListingsFilterChange(initialFilters);
  }

  function handleAddMonitor(name: string) {
    const filterJson = JSON.stringify(filters);

    const monitor = {
      guid: uuidv4(),
      name: name === "" ? GenerateMonitorName(filters) : name,
      eventId,
      filterJson,
      notificationJson: "",
      expiresAt: addDays(eventDate, 1),
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    addMonitor(monitor)
      .then(handleReset)
      .then(() => setIsMonitorDialogOpen(false))
      .then(() => setMonitorName(""))
      .then(() => {
        onNewMonitor(monitor);
      });
  }

  function handleKeyPress(e: KeyboardEvent) {
    if (e.key === "Enter") {
      handleAddMonitor(monitorName);
    }
  }
  
  useEffect(() => {
    setMonitorName(GenerateMonitorName(filters));
  }, [filters]);

  const isFilterDirty = JSON.stringify(filters) !== JSON.stringify(initialFilters);

  const marketplaces =
    exchangeIds?.map((e) => {
      switch (e.exchange) {
        case 1:
          return ExchangeEnum["Vivid Seats"];
        case 2:
          return ExchangeEnum.StubHub;
        case 3:
          return ExchangeEnum.Ticketmaster;
        case 4:
          return ExchangeEnum["Ticket Evolution"];
        case 5:
          return ExchangeEnum.TicketNetwork;
        case 6:
          return ExchangeEnum["Logitix Live"];
        case 7:
          return ExchangeEnum.TickPick;
        default:
          return ExchangeEnum.None;
      }
    }) ?? [];

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Composition areas={areas} gap="1rem">
          {({ Sections, Rows, Quantity, Price, Advanced, Clear }) => (
            <>
              <Sections>
                <PopoverTextInputButton
                  buttonPlaceholder="Any Section"
                  buttonPrefix="Sections: "
                  inputPlaceholder="Sections"
                  value={filters.sectionsFilter}
                  onChange={handleSectionFilterChange}
                />
              </Sections>
              <Rows>
                <PopoverTextInputButton buttonPlaceholder="Any Row" buttonPrefix="Rows: " inputPlaceholder="Rows" value={filters.rowsFilter} onChange={handleRowFilterChange} />
              </Rows>
              <Quantity>
                <QuantityFilter selectedValues={filters.splitsFilter} onChange={handleQuantityFilterChange} />
              </Quantity>
              <Price>
                <PriceRangeFilter initialRange={[0, 1000]} selectedRange={[filters.minPrice, filters.maxPrice]} onChange={handlePriceRangeFilterChange} />
              </Price>
              <Advanced>
                <ListingsFilterAdvanced exchanges={marketplaces} onExchangesFilterChange={handleExchangeFilterChange} />
              </Advanced>
              <Clear>{isFilterDirty && <Button icon="cross" onClick={handleReset} />}</Clear>
            </>
          )}
        </Composition>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Composition areas={"monitors links"} gap={"1rem"}>
          {({ Links, Monitors }) => (
            <>
              <Monitors>
                <Button
                  disabled={!isFilterDirty}
                  loading={isAddingMonitor}
                  intent={isFilterDirty ? Intent.PRIMARY : Intent.NONE}
                  icon="pulse"
                  fill={true}
                  onClick={() => setIsMonitorDialogOpen(true)}
                >
                  Add Monitor
                </Button>
                <Dialog isOpen={isMonitorDialogOpen} onClose={() => setIsMonitorDialogOpen(false)} title={"Monitor Name"}>
                  <div className={Classes.DIALOG_BODY}>
                    <FormGroup label="Monitor Name" labelFor="monitor-name">
                      <TextInputClearable id="monitor-name" value={monitorName} placeholder={monitorName} onChange={(e) => setMonitorName(e.target.value)} onKeyPress={handleKeyPress} onClearClick={() => setMonitorName("")} />
                    </FormGroup>
                  </div>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button intent={Intent.PRIMARY} onClick={() => handleAddMonitor(monitorName)} loading={isAddingMonitor}>
                        Save Monitor
                      </Button>
                      <Button onClick={() => setIsMonitorDialogOpen(false)} loading={isAddingMonitor}>Cancel</Button>
                    </div>
                  </div>
                </Dialog>
              </Monitors>
              <Links>
                <EventLinkButtonGroup exchangeIds={exchangeIds} onChange={() => window.location.reload()} />
              </Links>
            </>
          )}
        </Composition>
      </Navbar.Group>
    </Navbar>
  );
}

function GenerateMonitorName(filters: IListingsFilters) {
  const sections = filters.sectionsFilter.length > 0 ? `Sec: ${filters.sectionsFilter}` : "";
  const rows = filters.rowsFilter.length > 0 ? `Row: ${filters.rowsFilter}` : "";
  const quantity = filters.splitsFilter.length > 0 ? `Qty: ${filters.splitsFilter.join(", ")}` : "";
  const price = filters.minPrice > 0 || filters.maxPrice < 1000 ? `$: ${filters.minPrice} - ${filters.maxPrice}` : "";

  return `${sections} ${rows} ${quantity} ${price}`.trim();
}
