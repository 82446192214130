import { Navbar } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../rootReducer";
import { SelectedEventTitle } from "./SelectedEventTitle";

export function PageTitle() {
  const location = useLocation();
  const selectedEvent = useSelector((state: RootState) => state.selectedEvent.event);

  if (selectedEvent) {
    return (
      <SelectedEventTitle
        eventName={selectedEvent.name}
        eventDate={selectedEvent.date}
        venueName={selectedEvent.venue.name}
        city={selectedEvent.venue.city}
        region={selectedEvent.venue.region}
      />
    );
  }

  let title = "";

  if (location.pathname.toLowerCase().startsWith("/purchases")) {
    title = "Purchases";
  }

  if (title === "") {
    return null;
  }

  return (
    <>
      <Navbar.Divider />
      <Navbar.Heading>{title}</Navbar.Heading>
    </>
  );
}
