import { combineReducers } from "@reduxjs/toolkit";
import walletReducer from "./slices/WalletSlice";
import selectedEventReducer from "./slices/SelectedEventSlice";
import integrationsReducer from "./slices/IntegrationsSlice";
import subscriptionsReducer from "./slices/SubscriptionsSlice";

const rootReducer = combineReducers({
  wallet: walletReducer,
  selectedEvent: selectedEventReducer,
  integrations: integrationsReducer,
  subscription: subscriptionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
