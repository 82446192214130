import React, { useState } from "react";
import { Composition } from "atomic-layout";
import { addDays, subDays } from "date-fns";
import { ControlGroup, Label, Switch } from "@blueprintjs/core";
import { IPerformer } from "../../models/IPerformer";
import { IdNameSuggest } from "../shared/IdNameSuggest";
import { handleBooleanChange } from "../shared/HTMLInputElementHandlers";
import { DateFilter } from "../shared/DateFilter";

const areas = `
    dates performers cities parking
`;

export interface ISearchResultsFiltersProps {
  onPerformersFilterChange(performer: IPerformer | null): void;

  onCitiesFilterChange(city: string | null): void;

  onParkingFilterChange(includeParking: boolean): void;

  onMinDateFilterChange(minDate: Date | undefined): void;

  onMaxDateFilterChange(maxDate: Date | undefined): void;

  performers?: IPerformer[];
  cities?: string[];
}

const earliestDate = subDays(new Date(), 1);
const latestDate = addDays(new Date(), 365 * 3);

export function SearchResultsFilters({ onParkingFilterChange, onMinDateFilterChange, onMaxDateFilterChange, ...props }: ISearchResultsFiltersProps) {
  const [includeParking, setIncludeParking] = useState(false);
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  return (
    <Composition areas={areas} gap="1rem" margin="1rem" templateCols="1fr 1fr 1fr auto">
      {({ Dates, Performers, Cities, Parking }) => (
        <>
          <Dates justify="center">
            <ControlGroup>
              <DateFilter
                labelText="Start Date"
                placeholder="Start Date"
                minDate={earliestDate}
                maxDate={latestDate}
                value={minDate}
                onChange={(selectedDate, isUserChange) => {
                  if (isUserChange) {
                    setMinDate(selectedDate);
                    onMinDateFilterChange(selectedDate);
                  }
                }}
              ></DateFilter>
              <DateFilter
                labelText="End Date"
                placeholder="End Date"
                minDate={earliestDate}
                maxDate={latestDate}
                value={maxDate}
                onChange={(selectedDate, isUserChange) => {
                  if (isUserChange) {
                    setMaxDate(selectedDate);
                    onMaxDateFilterChange(selectedDate);
                  }
                }}
              ></DateFilter>
            </ControlGroup>
          </Dates>
          <Performers justify="center">
            <IdNameSuggest labelText="Performer" items={props.performers ?? []} onChange={props.onPerformersFilterChange} />
          </Performers>
          <Cities justify="center">
            <IdNameSuggest labelText="Location" items={props.cities ?? []} onChange={props.onCitiesFilterChange} />
          </Cities>
          <Parking>
            <Label>
              Include Parking
              <Switch
                style={{ marginTop: "8px" }}
                large={true}
                checked={includeParking}
                onChange={handleBooleanChange((included) => {
                  setIncludeParking(included);
                  onParkingFilterChange(included);
                })}
              />
            </Label>
          </Parking>
        </>
      )}
    </Composition>
  );
}
