import { Composition } from "atomic-layout";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getPurchases } from "../../nexusApi";
import { IPurchasesGridFilterState, PurchasesFilter } from "./PurchasesFilter";
import { PurchasesGrid } from "./PurchasesGrid";

const areas = `
    filters
    grid
`;

export interface IPurchasePageProps {
  purchaseId?: string;
}

export function PurchasesPage({ purchaseId }: IPurchasePageProps) {
  const { data } = useQuery("purchases", () => getPurchases().then((res) => res.data.data), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const [filterState, setFilterState] = useState<IPurchasesGridFilterState>({
    textFilter: "",
    dateRange: undefined,
    exchangeId: undefined,
  });

  return (
    <Composition style={{ height: "calc(100vh - 50px)" }} areas={areas} templateRows="auto 1fr">
      {({ Filters, Grid }) => (
        <>
          <Filters>
            <PurchasesFilter initialTextFilter={purchaseId} onFilterChange={setFilterState} />
          </Filters>
          <Grid>
            <PurchasesGrid rowData={data} filters={filterState} />
          </Grid>
        </>
      )}
    </Composition>
  );
}
