import React, { useState } from "react";
import { IUserDrawerProps } from "../IUserDrawer";
import { Button, Classes, Drawer, FormGroup, H2, InputGroup, Intent, Position, Switch } from "@blueprintjs/core";
import { Composition } from "atomic-layout";

const areas = `
    emails
    save
`;

export function ProfileDrawer({ onClose, isOpen, ...props }: IUserDrawerProps) {
  const [isDirty] = useState(false);

  function handleClose() {
    onClose();
  }

  return (
    <Drawer
      icon="person"
      title="Profile"
      canOutsideClickClose={!isDirty}
      canEscapeKeyClose={!isDirty}
      position={Position.RIGHT}
      isOpen={isOpen}
      hasBackdrop={isOpen}
      onClose={() => handleClose()}
      {...props}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <Composition areas={areas} gap="2rem" justifyItems="center">
            {(Areas) => (
              <>
                <Areas.Emails width="600px">
                  <H2>Emails</H2>
                  <FormGroup label="Login" labelInfo="(cannot be changed)" labelFor="email-login">
                    <InputGroup id="email-login" disabled value="levi@brokernerds.com" />
                  </FormGroup>
                  <FormGroup label="Billing" labelFor="email-billing">
                    <InputGroup id="email-billing" value="accounting@brokernerds.com" />
                  </FormGroup>
                  <FormGroup label="Notifications" labelFor="email-notifications">
                    <InputGroup id="email-notifications" value="levi@brokernerds.com" />
                  </FormGroup>
                  <FormGroup label="Preferences">
                    <Switch label="Allow notification emails" checked={true} />
                    <Switch label="Allow marketing emails" checked={false} />
                  </FormGroup>
                </Areas.Emails>
                <Areas.Save>
                  <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                      <Button text="Cancel" onClick={() => handleClose()} />
                      <Button text="Save Changes" intent={Intent.PRIMARY} />
                    </div>
                  </div>
                </Areas.Save>
              </>
            )}
          </Composition>
        </div>
      </div>
    </Drawer>
  );
}
