import { H2, Card, Elevation, Tag, Intent, Button, PanelStack, Classes } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { fetchPointOfSaleIntegration, removePointOfSaleIntegration } from "../../../../slices/IntegrationsSlice";
import { PointOfSaleLogo } from "../../../shared/PointOfSaleLogo";
import { NoPointOfSalePanel } from "./NoPointOfSalePanel";
import { PopoverConfirmDanger } from "../../../shared/PopoverConfirmDanger";

const StyledPanelStack = styled(PanelStack)`
  height: 300px;
`;

export function PointOfSaleIntegrationCard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPointOfSaleIntegration());
  }, [dispatch]);

  const { id, name, status } = useSelector((state: RootState) => state.integrations.pointOfSale);

  if (id === undefined || name === undefined || name === "None") {
    return (
      <div style={{ width: "600px", height: "400px" }}>
        <H2>Point of Sale</H2>
        <StyledPanelStack
          initialPanel={{
            component: NoPointOfSalePanel,
            title: "No Point of Sale",
          }}
          className={Classes.ELEVATION_2}
        />
      </div>
    );
  }

  return (
    <div style={{ width: "600px" }}>
      <H2>Point of Sale</H2>
      <Card elevation={Elevation.TWO}>
        <Composition areas="logo status buttons" gap="1rem" alignItems="center" justifyItems="center" templateCols="auto 1fr auto">
          {({ Logo, Status, Buttons }) => (
            <>
              <Logo>
                <PointOfSaleLogo name={name} />
              </Logo>
              <Status>
                <Tag icon="tick" large={true} minimal={true} intent={status === "Active" ? Intent.SUCCESS : Intent.WARNING}>
                  {status}
                </Tag>
              </Status>
              <Buttons>
                <PopoverConfirmDanger
                  titleText="Confirm disconnect"
                  messageText="Are you sure you want to disconnect your point of sale? You will not be able to use Nexus without it."
                  confirmButtonLabel="Disconnect"
                  onConfirmClick={() => dispatch(removePointOfSaleIntegration())}
                >
                  <Button large={true}>Disconnect</Button>
                </PopoverConfirmDanger>
              </Buttons>
            </>
          )}
        </Composition>
      </Card>
    </div>
  );
}
