import React, { useState } from "react";
import { Button, ButtonGroup } from "@blueprintjs/core";
import { Popover2, Classes } from "@blueprintjs/popover2";

export interface IQuantityFilterProps {
  selectedValues: number[];

  onChange(newValues: number[]): void;
}

export function QuantityFilter({ selectedValues, onChange }: IQuantityFilterProps) {
  const [isOpen, setIsOpen] = useState(false);

  let splits = Array(8).fill(false) as boolean[];

  splits.forEach((_, index) => {
    if (selectedValues.includes(index + 1)) {
      splits.splice(index, 1, true);
    }
  });

  const toggleSplit = (index: number) => {
    splits.splice(index, 1, !splits[index]);

    let results: number[] = [];

    splits.forEach((s, i) => {
      if (s) {
        results.push(i + 1);
      }
    });

    onChange(results);
  };

  const clearSplits = () => {
    onChange([]);
  };

  const labelText = selectedValues.length === 0 ? "Any Quantity" : "Qty: " + selectedValues.sort((a, b) => a - b).join(", ");

  return (
    <Popover2
      content={
        <ButtonGroup>
          {splits.map((split, i) => {
            return <Button key={i} active={split} onClick={() => toggleSplit(i)} text={i + 1} />;
          })}
        </ButtonGroup>
      }
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ButtonGroup>
        <Button rightIcon="caret-down" text={labelText} onClick={() => setIsOpen(!isOpen)} />
        {splits.some((x) => x) && <Button icon="cross" onClick={() => clearSplits()} />}
      </ButtonGroup>
    </Popover2>
  );
}
