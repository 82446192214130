import { IEventPageState } from "../components/event/EventPage";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../models/IEvent";

const selectedEvent = createSlice({
  name: "selectedEvent",
  initialState: {} as IEventPageState,
  reducers: {
    getEventSuccess(state, action: PayloadAction<IEvent>) {
      state.event = action.payload;
    },
    clearSelectedEvent(state) {
      state.event = undefined;
      state.listings = undefined;
      state.selectedListing = undefined;
    },
  },
});

export const { getEventSuccess, clearSelectedEvent } = selectedEvent.actions;

export default selectedEvent.reducer;
