import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnchorButton, Button, Card, Classes, Dialog, Elevation, H1, H4, H5, Intent, Tooltip } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { PayPalButton } from "react-paypal-button-v2";
import config from "../../../../config/paypal_config.json";
import { addSubscription } from "../../../../slices/SubscriptionsSlice";

export function PayPalIntegrationCard({ id, name, description, billingPeriod, pricePerPeriod, freeTrialCycles, freeTrialPeriod, currentSubscriptionPlanId }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isSubscriptionLoading } = useSelector((state) => state.subscription);
  const dispatch = useDispatch();

  if (id && Object.keys(id).length === 0 && id.constructor === Object) return null;

  let billingPeriodAbbreviation = "mo";

  switch (billingPeriod) {
    case "YEAR":
      billingPeriodAbbreviation = "yr";
      break;
    case "WEEK":
      billingPeriodAbbreviation = "wk";
      break;
    case "DAY":
      billingPeriodAbbreviation = "day";
      break;
    default:
      break;
  }

  const areas = `
    description
    price
    action`;

  function handleOnClose() {
    setIsDialogOpen(false);
  }

  return (
    <Card elevation={Elevation.TWO} style={{ width: "260px", margin: "1em" }}>
      <Composition areas={areas} gap="1rem" height="100%" templateRows="1fr auto auto">
        {(Areas) => (
          <>
            <Areas.Description>
              <H5>{name}</H5>
              <p className={Classes.TEXT_MUTED}>{description}</p>
            </Areas.Description>
            <Areas.Price align="end">
              <H1 style={{ display: "inline" }}>${pricePerPeriod}</H1>
              <H4 style={{ display: "inline" }} className={Classes.TEXT_MUTED}>
                /{billingPeriodAbbreviation}
              </H4>
            </Areas.Price>
            <Areas.Action align="end">
              {currentSubscriptionPlanId === undefined && (
                <Button loading={isSubscriptionLoading} large={true} fill={true} onClick={() => setIsDialogOpen(true)}>
                  Subscribe
                </Button>
              )}
              {currentSubscriptionPlanId === id && (
                <Tooltip content="Login to paypal.com if you wish to unsubscribe" fill={true}>
                  <AnchorButton loading={isSubscriptionLoading} large={true} disabled={true} fill={true} intent={Intent.SUCCESS} icon="endorsed">
                    Subscribed
                  </AnchorButton>
                </Tooltip>
              )}
              {currentSubscriptionPlanId !== undefined && currentSubscriptionPlanId !== id && (
                <Tooltip content="Login to paypal.com if you wish to unsubscribe" fill={true}>
                  <AnchorButton loading={isSubscriptionLoading} large={true} disabled={true} fill={true}>
                    Subscribe
                  </AnchorButton>
                </Tooltip>
              )}
            </Areas.Action>
          </>
        )}
      </Composition>
      <Dialog icon="credit-card" isOpen={isDialogOpen} title="Confirm Subscription" onClose={handleOnClose}>
        <div className={Classes.DIALOG_BODY}>
          <H5>{name}</H5>
          <p>All subscription payments are handled by PayPal. We do not store any payment-related data.</p>
          {freeTrialCycles > 0 && (
            <p>
              <strong>
                Your subscription includes a free trial for a period of {freeTrialCycles} {freeTrialPeriod.toLowerCase()}(s)
              </strong>
            </p>
          )}
          <p>You may cancel your subscription at any time via the paypal.com</p>
          <PayPalButton
            options={{
              vault: true,
              clientId: config.clientId,
            }}
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: id,
              });
            }}
            onApprove={(data, actions) => {
              setIsDialogOpen(false);
              dispatch(addSubscription(data.subscriptionID));
            }}
          />
        </div>
      </Dialog>
    </Card>
  );
}

/*

*/
