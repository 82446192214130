import { Label } from "@blueprintjs/core";
import { DateInput, IDateInputProps } from "@blueprintjs/datetime";
import React from "react";
import { getDateInputProps } from "../../utilities/DateTimeHelpers";

export interface IDateFilterProps extends Omit<IDateInputProps, "parseDate" | "formatDate"> {
  labelText?: string;
}

export function DateFilter({ labelText, ...props }: IDateFilterProps) {
  if (labelText) {
    return (
      <Label>
        {labelText}
        <DateInput {...getDateInputProps("M/d/yyyy")} showActionsBar={true} highlightCurrentDay={true} inputProps={{ leftIcon: "calendar", style: { width: "16ch" } }} {...props} />
      </Label>
    );
  }

  return (
    <DateInput {...getDateInputProps("M/d/yyyy")} showActionsBar={true} highlightCurrentDay={true} inputProps={{ leftIcon: "calendar", style: { width: "16ch" } }} {...props} />
  );
}
