import { Button, HTMLTable } from "@blueprintjs/core";
import React from "react";
import { IMonitorWithSummary } from "../../models/IMonitorWithSummary";
import { useMutation } from "react-query";
import { deleteMonitor } from "../../nexusApi";
import { PopoverConfirmDanger } from "../shared/PopoverConfirmDanger";
import { IListingsFilters } from "../event/EventPage";
import { Tooltip2 } from "@blueprintjs/popover2";
import { MonitorNotificationSettingsDialog } from "./MonitorNotificationSettingsDialog";
import { useState } from "react";

export interface IMonitorTableProps {
  monitors: IMonitorWithSummary[];

  onLoadMonitorCallback(monitorFilter: IListingsFilters, monitorGUID: string | undefined): void;
  onUpdateMonitorCallback(): void;
  onDeleteMonitorCallback(monitorGuid: string): void;
}


// the data for the table should be passed props
export function MonitorTable({ monitors, onLoadMonitorCallback, onUpdateMonitorCallback, onDeleteMonitorCallback }: IMonitorTableProps) {
  const [removeMonitor] = useMutation(deleteMonitor);
  const [selectedMonitor, setSelectedMonitor] = useState<IMonitorWithSummary>();

  function handleDeleteMonitorClick(guid: string) {
    removeMonitor(guid).then(() => {
      if (onDeleteMonitorCallback) {
        onDeleteMonitorCallback(guid);
      }
    });
  }

  function handleLoadMonitorClick(guid: string) {
    const monitorJson = monitors.find((m) => m.guid === guid)?.filterJson;
    const monitorGUID = monitors.find((m) => m.guid === guid)?.guid;
    if (!monitorJson) {
      return;
    }

    const filter = JSON.parse(monitorJson) as IListingsFilters;
    onLoadMonitorCallback(filter, monitorGUID);
  }

  return (
    <>
      <HTMLTable striped={true} bordered={false} style={{ width: "100%" }}>
        <thead>
          <tr style={{ height: "49px", background: "#F8F8F8" }}>
            <th style={{ whiteSpace: "nowrap", paddingTop: "15px" }}>Now</th>
            <th style={{ whiteSpace: "nowrap", paddingTop: "15px" }}>1 d</th>
            <th style={{ whiteSpace: "nowrap", paddingTop: "15px" }}>7 d</th>
            <th style={{ width: "100%", paddingTop: "15px" }}>Monitor Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {monitors.map((monitor, i) => {
            return (
              <tr key={i}>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{monitor.snapshot0Day?.numTickets ?? 0}</td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{monitor.snapshot1Day?.numTickets ?? 0}</td>
                <td style={{ textAlign: "right", verticalAlign: "middle" }}>{monitor.snapshot7Day?.numTickets ?? 0}</td>
                <td style={{ verticalAlign: "middle" }}>{monitor.name}</td>
                <td style={{ whiteSpace: "nowrap", display: "flex", gap: "1rem" }}>
                  <Button minimal={true} outlined={true} onClick={() => handleLoadMonitorClick(monitor.guid)}>
                    Load Monitor
                  </Button>
                  <Tooltip2 content={monitor.notificationJson === "{}" ? "Create notification" : "Edit notification"}>
                    <Button
                      minimal={true}
                      outlined={true}
                      intent={monitor.notificationJson === "{}" ? "none" : "success"}
                      icon={monitor.notificationJson === "{}" ? "notifications" : "notifications-updated"}
                      onClick={() => setSelectedMonitor(monitor)}
                    />
                  </Tooltip2>
                  <PopoverConfirmDanger
                    titleText={"Confirm Delete"}
                    messageText={"Are you sure you want to delete this monitor?"}
                    confirmButtonLabel={"Delete"}
                    onConfirmClick={() => handleDeleteMonitorClick(monitor.guid)}
                  >
                    <Tooltip2 content={"Delete monitor"}>
                      <Button icon="trash" minimal={true} outlined={true} />
                    </Tooltip2>
                  </PopoverConfirmDanger>
                </td>
              </tr>
            );
          })}
        </tbody>
      </HTMLTable>
      <MonitorNotificationSettingsDialog monitor={selectedMonitor} isOpen={selectedMonitor !== undefined} onClose={() => setSelectedMonitor(undefined)} onSubmit={onUpdateMonitorCallback} />
    </>
  );
}
