import React, { useEffect, useState } from "react";
import { IEvent } from "../../models/IEvent";
import EventTable from "./EventTable";
import { Composition } from "atomic-layout";
import { SearchResultsFilters } from "./SearchResultsFilters";
import { H5, Classes, Spinner } from "@blueprintjs/core";
import { useLocation } from "react-router";
import queryString from "query-string";
import * as _ from "lodash";
import { IPerformer } from "../../models/IPerformer";
import { searchCatalogEvents } from "../../nexusApi";
import { useQuery } from "react-query";
import { addDays, subMilliseconds } from "date-fns";

const areas = `
    filters
    results
`;

export interface ISearchResultsProps {
  query?: string;
}

export interface IEventFilters {
  minDateFilter: Date | undefined;
  maxDateFilter: Date | undefined;
  performerFilter: IPerformer | null;
  cityFilter: string | null;
  includeParking: boolean;
}

export interface ISearchResultsState {
  isSearching: boolean;
  results?: IEvent[];
  filters: IEventFilters;
}

function applyFilters(events: IEvent[] | undefined, filters: IEventFilters | undefined): IEvent[] {
  if (!events || events.length === 0) {
    return [];
  }

  if (!filters) {
    return events.filter((e) => e.name.toLowerCase().indexOf("parking") === -1); // Don't include parking by default
  }

  return _.chain(events)
    .filter((e) => !filters.minDateFilter || e.date >= filters.minDateFilter)
    .filter((e) => !filters.maxDateFilter || e.date <= filters.maxDateFilter)
    .filter((e) => !filters.performerFilter || e.primaryPerformer.id === filters.performerFilter.id)
    .filter((e) => !filters.cityFilter || e.venue.city + ", " + e.venue.region === filters.cityFilter)
    .filter((e) => e.name.toLowerCase().indexOf("parking") === -1 || filters?.includeParking)
    .value();
}

export function SearchResults({ query }: ISearchResultsProps) {
  const location = useLocation();

  const { data: results, isLoading, isFetching, refetch } = useQuery(
    "results",
    () =>
      searchCatalogEvents(query ?? (queryString.parse(location.search).q as string)).then((res) =>
        _.chain(res.data.data)
          .orderBy((e) => e.date)
          .value()
      ),
    { refetchOnWindowFocus: false }
  );

  const [filteredResults, setFilteredResults] = useState(results);

  const [filters, setFilters] = useState<IEventFilters>();

  useEffect(() => {
    refetch();
  }, [location.search, refetch]);

  useEffect(() => {
    setFilteredResults(applyFilters(results, filters));
  }, [results, filters]);

  function handlePerformerFilterChange(performer: IPerformer | null) {
    let newFilters = Object.assign({}, filters);
    newFilters.performerFilter = performer;
    setFilters(newFilters);
  }

  function handleCitiesFilterChange(city: string | null) {
    let newFilters = Object.assign({}, filters);
    newFilters.cityFilter = city;
    setFilters(newFilters);
  }

  function handleParkingFilterChange(includeParking: boolean) {
    let newFilters = Object.assign({}, filters);
    newFilters.includeParking = includeParking;
    setFilters(newFilters);
  }

  function handleMinDateFilterChange(minDate: Date | undefined) {
    let newFilters = Object.assign({}, filters);
    newFilters.minDateFilter = minDate;
    console.log(minDate);
    setFilters(newFilters);
  }

  function handleMaxDateFilterChange(maxDate: Date | undefined) {
    let newFilters = Object.assign({}, filters);

    if (maxDate !== undefined) {
      // The date comes over without time, since this is max date, we need to include the full day, so add 1 day and subtract 1 ms
      maxDate = subMilliseconds(addDays(maxDate, 1), 1);
    }
    console.log(maxDate);

    newFilters.maxDateFilter = maxDate;
    setFilters(newFilters);
  }

  if (isLoading || isFetching) {
    return <Spinner />;
  }

  return (
    <Composition areas={areas} gap="1rem" margin="1rem" width="calc(100vw - 2rem)" height="calc(100vh - 6rem)" templateRows="auto 2fr">
      {({ Filters, Results }) => (
        <>
          <Filters width="800px" justify="center">
            <SearchResultsFilters
              performers={_.chain(results)
                .map((e) => e.primaryPerformer)
                .uniqBy((p) => p.id)
                .sortBy((p) => p.name)
                .value()}
              cities={_.chain(results)
                .map((e) => e.venue.city + ", " + e.venue.region)
                .uniq()
                .sort()
                .value()}
              onPerformersFilterChange={handlePerformerFilterChange}
              onCitiesFilterChange={handleCitiesFilterChange}
              onParkingFilterChange={handleParkingFilterChange}
              onMinDateFilterChange={handleMinDateFilterChange}
              onMaxDateFilterChange={handleMaxDateFilterChange}
            />
          </Filters>
          <Results width="800px" justify="center">
            <H5 className={Classes.TEXT_MUTED}>Matching Events</H5>
            <EventTable events={filteredResults} />
          </Results>
        </>
      )}
    </Composition>
  );
}
