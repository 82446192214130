import React from "react";
import { HTMLTable, H5, Classes, Tooltip } from "@blueprintjs/core";
import { format } from "date-fns";
import { Composition } from "atomic-layout";
import { Button } from "@blueprintjs/core";
import { IEvent } from "../../models/IEvent";
import { useNavigate } from "react-router-dom";
import { shortDayFormatter, shortEventTimeFormatter } from "../../utilities/Formatters";

export interface IEventTableProps {
  events?: IEvent[];
}

function EventTable(props: IEventTableProps) {
  const navigate = useNavigate();

  if (!props.events || props.events.length === 0) return null;

  const eventAreas = `
        event icons
    `;

  function handleOnClick(eventId: number) {
    navigate(`/event/${eventId}`);
  }

  return (
    <HTMLTable interactive={true} striped={false} bordered={true} style={{ width: "100%" }}>
      <tbody>
        {props.events.map((event, i) => {
          if (!event.date) return null;

          return (
            <tr key={i} onClick={() => handleOnClick(event.id)}>
              <td style={{ width: "0.1%", whiteSpace: "nowrap" }}>
                <H5>{format(event.date, "MMM d u")}</H5>
                <strong className={Classes.TEXT_MUTED}>
                  {shortDayFormatter(event.date)} - {shortEventTimeFormatter(event.date)}
                </strong>
              </td>
              <td>
                <Composition areas={eventAreas} gap="1rem" width="100%" templateCols="auto auto">
                  {({ Event, Icons }) => (
                    <>
                      <Event>
                        <H5>{event.name}</H5>
                        <strong className={Classes.TEXT_MUTED}>
                          {event.venue.name} - {event.venue.city}, {event.venue.region}
                        </strong>
                      </Event>
                      <Icons justify="end" align="center">
                        {event.isFavorite && (
                          <Tooltip content="Favorite">
                            <Button icon="heart" minimal={true} />
                          </Tooltip>
                        )}
                        {event.isWatched && (
                          <Tooltip content="Watching">
                            <Button icon="eye-open" minimal={true} />
                          </Tooltip>
                        )}
                      </Icons>
                    </>
                  )}
                </Composition>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}

export default EventTable;
