import React, { useEffect, useState } from "react";
import { EventPageNavBar } from "./EventPageNavBar";
import { ListingsGrid } from "./ListingsGrid";
import { Composition } from "atomic-layout";
import { IEvent } from "../../models/IEvent";
import { IListing } from "../../models/IListing";
import { filterRows, filterSections } from "../shared/Filters";
import { PurchaseEventListingCard } from "../checkout/PurchaseEventListingCard";
import { IPurchaseResponse } from "../../models/IPurchaseResponse";
import { PurchaseFinalizeCard } from "../checkout/PurchaseFinalizeCard";
import { useQuery } from "react-query";
import { getEvent, getEventListings, getEventMappings, getMonitorsByEventId } from "../../nexusApi";
import { Spinner } from "@blueprintjs/core";
import { clearSelectedEvent, getEventSuccess } from "../../slices/SelectedEventSlice";
import { useDispatch } from "react-redux";
import { MonitorTable } from "../monitors/MonitorTable";
import { SummaryItem } from "../shared/SummaryItem";
import { Colors } from "@blueprintjs/core";
import Funnel from "../../images/icons/svg_icons/filter.svg";
import Ticket from "../../images/icons/svg_icons/ticket.svg";
import List from "../../images/icons/svg_icons/list.svg";
import { MonitorTrendChart } from "../monitors/MonitorTrendChart";
export interface IEventPageState {
  event: IEvent | undefined;
  listings: IListing[] | undefined;
  selectedListing: IListing | undefined;
  splitsFilter: number[] | undefined;
}

export interface IListingsFilters {
  sectionsFilter: string;
  rowsFilter: string;
  splitsFilter: number[];
  minPrice: number;
  maxPrice: number;
  exchangesFilter: number[];
  stockTypesFilter: number[];
}

export const initialFilters: IListingsFilters = {
  sectionsFilter: "",
  rowsFilter: "",
  splitsFilter: [],
  minPrice: 0,
  maxPrice: 1000,
  exchangesFilter: [4, 2, 5, 6, 1, 3, 7], // These are in this specific order for a reason...
  stockTypesFilter: [],
};

export interface IEventPageProps {
  eventId: string;
}

export function EventPage(props: IEventPageProps) {
  const dispatch = useDispatch();
  const eventId = parseInt(props.eventId);

  const { data: eventInfo, isFetching: isFetchingEvent } = useQuery(["event", props.eventId], () => getEvent(eventId), { refetchOnWindowFocus: false });
  const { data: exchangeIds } = useQuery(["exchangeIds", eventId], () => getEventMappings(eventId).then((res) => res.data.data));
  const { data: listingInfo, isFetching: isFetchingListings, refetch: refetchListings } = useQuery(["listings", props.eventId], () => getEventListings(eventId), {
    enabled: eventInfo,
    refetchOnWindowFocus: false,
  });
  const { data: monitors, isFetching: isFetchingMonitors, refetch: refetchMonitors } = useQuery(["monitors", props.eventId], () => getMonitorsByEventId(eventId), {
    enabled: eventInfo,
    refetchOnWindowFocus: false,
  });

  const [filters, setFilters] = useState<IListingsFilters>(initialFilters);
  const [filteredListings, setFilteredListings] = useState<IListing[]>([]);
  const [selectedListing, setSelectedListing] = useState<IListing>();
  const [purchase, setPurchase] = useState<IPurchaseResponse>();
  const [loadedMonitorID, setLoadedMonitorID] = useState<string>();
  const event = eventInfo?.data.data;

  useEffect(() => {
    if (isFetchingEvent || !eventInfo) {
      return;
    }

    dispatch(getEventSuccess(eventInfo.data.data));

    if (isFetchingListings) {
      return;
    }

    setFilteredListings(listingInfo?.data?.data ?? []);

    return () => {
      dispatch(clearSelectedEvent());
    };
  }, [dispatch, isFetchingEvent, isFetchingListings, eventInfo, listingInfo?.data?.data]);

  function handleFiltersChange(filters: IListingsFilters) {
    let temp = Array.from(listingInfo?.data?.data ?? []);
    temp = applyFilters(
      temp,
      filters.sectionsFilter,
      filters.rowsFilter,
      filters.splitsFilter,
      filters.minPrice,
      filters.maxPrice,
      filters.exchangesFilter,
      filters.stockTypesFilter
    );
    setFilters(filters);
    setFilteredListings(temp);
  }

  function applyFilters(
    listings: IListing[],
    sections: string,
    rows: string,
    splits: number[],
    minPrice: number,
    maxPrice: number,
    allowedExchanges: number[],
    stockTypes: number[]
  ): IListing[] {
    if (splits && splits.length > 0) {
      listings = listings.filter((l) => l.buyQuantityOptions?.some((q) => splits.some((s) => s === q)));
    }

    if (minPrice > 0 || maxPrice < 1000) {
      listings = listings.filter((l) => l.unitPrice >= minPrice && l.unitPrice < maxPrice);
    }

    if (allowedExchanges.length > 0) {
      listings = listings.filter((l) => allowedExchanges.some((e) => l.exchange.id === e));
    }

    listings = filterSections(listings, sections);

    listings = filterRows(listings, rows);

    return listings;
  }

  function getListingAmount() {
    return filteredListings?.length;
  }

  function getTicketQuantity() {
    return filteredListings.reduce((previousValue, currentValue) => previousValue + currentValue?.quantity, 0);
  }

  function ifFiltered() {
    let filtered = false;
    if (
      filters.maxPrice !== initialFilters.maxPrice ||
      filters.minPrice !== initialFilters.minPrice ||
      filters.rowsFilter !== initialFilters.rowsFilter ||
      filters.sectionsFilter !== initialFilters.sectionsFilter ||
      filters.splitsFilter.length !== 0 ||
      filters.stockTypesFilter.length !== 0 ||
      filters.exchangesFilter !== initialFilters.exchangesFilter
    ) {
      filtered = true;
    }
    return filtered;
  }

  function getFilterIcon() {
    if (ifFiltered()) {
      return (
        <div>
          <div style={{ width: "2px", height: "87px", background: "#D3D8DE", margin: "auto" }}></div>
          <div style={{ background: Colors.CERULEAN1, borderRadius: "50%", height: "35px", width: "33px", paddingLeft: "4px", paddingTop: "6px" }}>
            <img src={Funnel} alt="filter icon" style={{ filter: "invert(100%)" }} />
          </div>
          <div style={{ width: "2px", height: "97px", background: "#D3D8DE", margin: "auto" }}></div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "33px" }}>
          <div style={{ width: "2px", height: "207px", background: "#D3D8DE", margin: "auto" }}></div>
        </div>

      );
    }
  }

  function getTicketHeader() {
    if (ifFiltered()) {
      return <div style={{ color: Colors.BLACK, background: "#F8F8F8", borderStyle: "solid", borderWidth: "1px", borderColor: Colors.GRAY4, textAlign: "center", height: "50px", fontSize: "13px", lineHeight: "normal", fontWeight: 700, paddingTop: "15px", borderRight: "none", borderLeft: "none" }}>Filtered Results</div>;
    } else {
      return <div style={{ color: Colors.BLACK, background: "#F8F8F8", borderStyle: "solid", borderWidth: "1px", borderColor: Colors.GRAY4, textAlign: "center", height: "50px", fontSize: "13px", lineHeight: "normal", fontWeight: 700, paddingTop: "15px", borderRight: "none", borderLeft: "none" }}>Total Results</div>;
    }
  }

  function getUnfilteredListings() {
    const unfilteredListings = listingInfo?.data.data.length;
    if (ifFiltered()) {
      return "of " + unfilteredListings + " total";
    } else {
      return "Total";
    }
  }

  function getUnfilteredTickets() {
    const unfilteredTickets = listingInfo?.data.data.reduce((previousValue, currentValue) => previousValue + currentValue?.quantity, 0);
    if (ifFiltered()) {
      return "of " + unfilteredTickets + " total";
    } else {
      return "Total";
    }
  }

  const ticketIcon = <img src={Ticket} alt="ticket" />;
  const listIcon = <img src={List} alt="list" />;

  function ifMonitored() {
    const monitorData = monitors?.data?.data
    if (monitorData) {
      return monitorData.length > 0;
    }
    else {
      return false;
    }
  }

  function handleLoadMonitorGuid(guid: string) {
    if (loadedMonitorID !== guid) {
      const monitorGUID = monitors?.data.data.find((m) => m.guid === guid)?.guid;
      setLoadedMonitorID(monitorGUID);
    }
  }

  function handleLoadMonitorClick(filters: IListingsFilters, guid: string) {
    handleFiltersChange(initialFilters);
    handleLoadMonitorGuid(guid);
  }

  function handlePurchaseClose() {
    setSelectedListing(undefined);
    setPurchase(undefined);
    refetchListings();
  }

  if (isNaN(eventId) || !event) {
    return null;
  }

  if (isFetchingEvent || isFetchingListings) {
    return <Spinner />;
  }

  const pageAreas = `
        filters filters
        listings workspace
    `;

  const workspaceAreas = `eventSummary monitors 
                          monitorTrends monitors
                          seatingChart monitors`;

  return (
    <div style={{ height: "calc(100vh - 50px)" }}>
      <Composition areas={pageAreas} templateCols="1fr 2fr" height="100%" templateRows="auto 1fr" >
        {({ Filters, Listings, Workspace }) => (
          <>
            <Filters>
              <EventPageNavBar
                exchangeIds={exchangeIds}
                onListingsFilterChange={handleFiltersChange}
                onNewMonitor={() => refetchMonitors()}
                eventId={eventId}
                eventDate={event.date}
                filters={filters}
                initialFilters={initialFilters}
              />
            </Filters>
            <Listings width={ifMonitored() ? "140%" : "220%"}>
              <ListingsGrid listings={filteredListings} onSelectedListingChange={setSelectedListing} />
            </Listings>
            <Workspace>
              {!selectedListing && !purchase && (
                <Composition areas={workspaceAreas} templateCols={ifMonitored() ? "auto 1fr" : "auto 0px"} gap={"0px"} width={ifMonitored() ? "80%" : "40%"} style={{ position: "relative", float: "right" }}>
                  {({ EventSummary, MonitorTrends, SeatingChart, Monitors }) => (
                    <>
                      <EventSummary height="256px">
                        {getTicketHeader()}
                        <div style={{ display: "flex", justifyContent: "center", margin: 'auto' }}>
                          <SummaryItem title="Tickets" value={getTicketQuantity()} iconProps={{ ...ticketIcon.props, alt: "Tickets" }} label={getUnfilteredTickets()}></SummaryItem>
                          {getFilterIcon()}
                          <SummaryItem title="Listings" value={getListingAmount()} iconProps={{ ...listIcon.props, alt: "Listings" }} label={getUnfilteredListings()}></SummaryItem>
                        </div>

                      </EventSummary>
                      <MonitorTrends>
                        {ifMonitored() && loadedMonitorID !== undefined ?
                          <MonitorTrendChart monitorID={loadedMonitorID} />
                          : ""}
                      </MonitorTrends>
                      <SeatingChart style={{ position: "absolute" }}>
                        <div style={{ color: Colors.BLACK, background: "#F8F8F8", borderStyle: "solid", borderWidth: "1px", borderColor: Colors.GRAY4, textAlign: "center", height: "50px", fontSize: "13px", lineHeight: "normal", fontWeight: 700, paddingTop: "15px", borderLeft: "none", borderRight: "none" }}>Venue Map</div>
                        <img width="498px" src={`${event?.chartUrl}`} alt={`Seating chart for ${event?.venue.name}`} />
                      </SeatingChart>
                      {ifMonitored() ?
                        <Monitors style={{ borderLeft: "1px solid #8e9299" }}>
                          {ifMonitored() ?
                            (isFetchingMonitors || !monitors ? (
                              <Spinner />
                            ) : 
                                <MonitorTable
                                  monitors={monitors.data.data}
                                  onLoadMonitorCallback={handleLoadMonitorClick}
                                  onDeleteMonitorCallback={() => refetchMonitors()}
                                  onUpdateMonitorCallback={() => refetchMonitors()}
                                />
                            ) : ""}
                        </Monitors>
                        : ""}
                    </>
                  )}
                </Composition>
              )}
              {selectedListing && !purchase && (
                <PurchaseEventListingCard eventId={eventId} {...selectedListing} onPurchase={setPurchase} onCancel={() => setSelectedListing(undefined)} />
              )}
              {purchase && <PurchaseFinalizeCard {...purchase} event={event} onClose={handlePurchaseClose} />}
            </Workspace>
          </>
        )}
      </Composition>
    </div>
  );
}
