import { Icon, Button, FormGroup, Intent, InputGroup } from "@blueprintjs/core";
import { Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IExchangeIntegration } from "../../../../models/IIntegration";
import { addExchangeIntegration } from "../../../../slices/IntegrationsSlice";
import { Popover2, Classes } from "@blueprintjs/popover2";

interface TevoFormValues {
  secret: string;
  token: string;
}

function validateTevo(values: TevoFormValues) {
  const errors: FormikErrors<TevoFormValues> = {};

  if (!values.secret) {
    errors.secret = "Required";
  }

  if (!values.token) {
    errors.token = "Required";
  }

  return errors;
}

export function TicketEvolutionIntegrationMissing({ id, name }: IExchangeIntegration) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (params: TevoFormValues) => {
    setIsOpen(false);

    dispatch(addExchangeIntegration(id, JSON.stringify(params), "{}"));
  };

  return (
    <tr key={id}>
      <td>{name}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon="offline" />
      </td>
      <td style={{ textAlign: "center" }}>
        <Popover2
          content={
            <Formik initialValues={{ secret: "", token: "" }} onSubmit={onSubmit} validate={validateTevo}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form>
                  <FormGroup
                    label="Ticket Evolution API Secret"
                    helperText={(touched.secret && errors.token) ?? "Contact onboarding@ticketevolution.com for your API credentials"}
                    labelInfo="(required)"
                    labelFor="secret-input"
                  >
                    <InputGroup
                      id="secret-input"
                      placeholder="Secret"
                      autoFocus={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.secret}
                      intent={touched.secret && errors.secret ? Intent.WARNING : Intent.NONE}
                      name="secret"
                    />
                  </FormGroup>
                  <FormGroup
                    label="Ticket Evolution API Token"
                    helperText={"Contact onboarding@ticketevolution.com for your API credentials"}
                    labelInfo="(required)"
                    labelFor="token-input"
                  >
                    <InputGroup
                      id="token-input"
                      placeholder="Token"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.token}
                      intent={touched.token && errors.token ? Intent.WARNING : Intent.NONE}
                      name="token"
                    />
                  </FormGroup>

                  <Button
                    intent={Intent.PRIMARY}
                    disabled={isSubmitting || !!errors.secret || !!errors.secret || values.secret.length < 1 || values.token.length < 1}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Connect
                  </Button>
                </form>
              )}
            </Formik>
          }
          popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Button minimal={true} onClick={() => setIsOpen(!isOpen)}>
            Connect
          </Button>
        </Popover2>
      </td>
    </tr>
  );
}
