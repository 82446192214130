import React, { useState } from "react";
import { Card, Classes, Text, InputGroup, Button, NonIdealState, Intent, Colors, Dialog, Spinner } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SoftLabel } from "../shared/Typography";
import { IPosCustomer } from "../../models/IPosCustomer";
import { CustomerSearchDialog } from "./CustomerSearchDialog";
import { IPurchaseCustomer } from "../../models/IPurchaseCustomer";
import { useQuery } from "react-query";
import { lookupCustomer } from "../../nexusApi";

export interface IPurchaseCustomerCardProps extends IPurchaseCustomer {
  onCustomerSelect(customer: IPosCustomer): void;
}

export function PurchaseCustomerCard({ firstName, lastName, email, phone, onCustomerSelect }: IPurchaseCustomerCardProps) {
  const [selectedCustomer, setSelectedCustomer] = useState<IPosCustomer>();
  const [isSearchOpen, setSearchOpen] = useState(false);

  const { data: customerLookup, isLoading } = useQuery(["customerLookup", email], () => lookupCustomer(email).then((res) => res.data.data), { refetchOnWindowFocus: false });

  if (!selectedCustomer && customerLookup) {
    handleSelect(customerLookup);
  }

  function handleSelect(customer: IPosCustomer) {
    setSelectedCustomer(customer);
    setSearchOpen(false);
    onCustomerSelect(customer);
  }

  const searchDialog = (
    <Dialog icon="user" title="Customer Search" isOpen={isSearchOpen} onClose={() => setSearchOpen(false)} style={{ width: "1000px" }}>
      <CustomerSearchDialog onCancel={() => setSearchOpen(false)} onSelect={handleSelect} />
    </Dialog>
  );

  if (!selectedCustomer) {
    const action = (
      <Button icon="search" intent={Intent.PRIMARY} onClick={() => setSearchOpen(true)}>
        Locate
      </Button>
    );

    return (
      <Card
        style={{
          width: "400px",
          height: "361px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading && <Spinner />}
        {!isLoading && (
          <NonIdealState
            icon="search"
            title="No matching customer found"
            description={`The seller of these tickets was: ${firstName} ${lastName}. The email provided is: ${email} and phone number: ${phone}`}
            action={action}
          />
        )}
        {searchDialog}
      </Card>
    );
  }

  const areas = `
        header header
        . .
        firstName lastName
        company company
        phoneEmail phoneEmail
        address1 address1
        address2 address2
        . change
    `;

  return (
    <Card style={{ width: "400px", height: "361px" }}>
      <Composition areas={areas} gap="1rem">
        {(Areas) => (
          <>
            <Areas.Header>
              <Composition areas="label icon id">
                {(Header) => (
                  <>
                    <Header.Label>
                      <SoftLabel>Customer</SoftLabel>
                    </Header.Label>
                    <Header.Icon justify="center">
                      <FontAwesomeIcon icon="user-check" color={Colors.GRAY1} size="2x" />
                    </Header.Icon>
                    <Header.Id justify="end">
                      <Text className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>{selectedCustomer.id}</Text>
                    </Header.Id>
                  </>
                )}
              </Composition>
            </Areas.Header>
            <Areas.FirstName>
              <InputGroup readOnly={true} placeholder="First Name" value={selectedCustomer.firstName} />
            </Areas.FirstName>
            <Areas.LastName>
              <InputGroup readOnly={true} placeholder="Last Name" value={selectedCustomer.lastName} />
            </Areas.LastName>
            <Areas.Company>
              <InputGroup readOnly={true} leftIcon="office" placeholder="Company Name" value={selectedCustomer.companyName} />
            </Areas.Company>
            <Areas.PhoneEmail>
              <Composition areas="phone email" gapCol="1rem" templateCols="18ch auto">
                {(PhoneEmailAreas) => (
                  <>
                    <PhoneEmailAreas.Phone>
                      <InputGroup readOnly={true} leftIcon="phone" placeholder="Phone" value={selectedCustomer.phone} />
                    </PhoneEmailAreas.Phone>
                    <PhoneEmailAreas.Email>
                      <InputGroup readOnly={true} leftIcon="envelope" placeholder="Email" value={selectedCustomer.email} />
                    </PhoneEmailAreas.Email>
                  </>
                )}
              </Composition>
            </Areas.PhoneEmail>
            <Areas.Address1>
              <InputGroup readOnly={true} placeholder="Street Address" value={`${selectedCustomer.address1}${selectedCustomer.address2 ? ", " + selectedCustomer.address2 : ""}`} />
            </Areas.Address1>
            <Areas.Address2>
              <InputGroup readOnly={true} placeholder="Address Continued..." value={`${selectedCustomer.city}, ${selectedCustomer.region} ${selectedCustomer.postalCode}`} />
            </Areas.Address2>
            <Areas.Change justify="end" className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => setSelectedCustomer(undefined)}>Clear</Button>
              <Button
                icon="search"
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                Change
              </Button>
            </Areas.Change>
          </>
        )}
      </Composition>
      {searchDialog}
    </Card>
  );
}
