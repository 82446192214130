import React, { useState } from "react";
import { IEvent } from "../../models/IEvent";
import { Card, Text, Classes, NonIdealState, Intent, Colors, Spinner, Dialog } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { InputGroup } from "@blueprintjs/core";
import { Button } from "@blueprintjs/core";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SoftLabel } from "../shared/Typography";
import { getEventMappings, lookupEvent } from "../../nexusApi";
import { useQuery } from "react-query";
import { RootState } from "../../rootReducer";
import { useSelector } from "react-redux";
import { PosName } from "../../models/common/PosName";
import { IExchangeId } from "../../models/IExchangeId";
import { EventSearchDialog } from "./EventSearchDialog";

function getPosExchangeId(posName: PosName, exchangeIds: IExchangeId[] | undefined) {
  if (!exchangeIds) {
    return;
  }

  switch (posName) {
    case "SkyBox":
      return exchangeIds.find((x) => x.exchange === 1)?.id;
    case "PosNext":
      return exchangeIds.find((x) => x.exchange === 5)?.id;
    case "TradeDesk":
      return exchangeIds.find((x) => x.exchange === 3)?.id;
    default:
      return;
  }
}

export interface IPurchaseEventCardProps extends IEvent {
  onEventSelect(event: IEvent): void;
}

export function PurchaseEventCard({ id: eventId, name, date: eventDateTime, venue, onEventSelect }: IPurchaseEventCardProps) {
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();
  const [isSearchOpen, setSearchOpen] = useState(false);
  const { pointOfSale } = useSelector((state: RootState) => state.integrations);

  const { data: exchangeIds, isLoading: isMappingLoading } = useQuery(["exchangeIds", eventId], () => getEventMappings(eventId).then((res) => res.data.data));

  const { data: eventLookup, isLoading: isLookupLoading } = useQuery(
    ["eventLookup", exchangeIds, name, eventDateTime, venue.name],
    () => lookupEvent(name, eventDateTime, venue.name, getPosExchangeId(pointOfSale.name, exchangeIds)).then((res) => res.data.data),
    {
      enabled: exchangeIds,
      refetchOnWindowFocus: false,
    }
  );

  if (!selectedEvent && eventLookup && eventLookup.id > 0) {
    handleSelect(eventLookup);
  }

  function handleSelect(event: IEvent) {
    console.log("handling select event");

    setSelectedEvent(event);
    setSearchOpen(false);
    onEventSelect(event);
  }

  const searchDialog = (
    <Dialog icon="timeline-events" title="Event Search" isOpen={isSearchOpen} onClose={() => setSearchOpen(false)} style={{ width: "1000px" }}>
      <EventSearchDialog
        initialQuery={name}
        initialDateFrom={new Date(eventDateTime.getFullYear(), eventDateTime.getMonth(), eventDateTime.getDate())}
        initialDateTo={new Date(eventDateTime.getFullYear(), eventDateTime.getMonth(), eventDateTime.getDate(), 23, 59, 59)}
        onCancel={() => setSearchOpen(false)}
        onSelect={handleSelect}
      />
    </Dialog>
  );

  if (!selectedEvent || !selectedEvent?.id || selectedEvent.id <= 0) {
    const action = (
      <Button icon="search" intent={Intent.PRIMARY} onClick={() => setSearchOpen(true)}>
        Locate
      </Button>
    );

    return (
      <Card
        style={{
          width: "400px",
          height: "361px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLookupLoading || isMappingLoading ? (
          <Spinner />
        ) : (
          <NonIdealState icon="search" title="No matching event found" description="Use the search below to select an event from your POS" action={action} />
        )}
        {searchDialog}
      </Card>
    );
  }

  const areas = `
        header header
        . .
        name name
        date time
        venue venue
        location location
        . .
        . change
    `;

  return (
    <Card style={{ width: "400px", height: "361px" }}>
      <Composition areas={areas} gap="1rem" height="100%" templateRows="auto auto auto auto auto auto auto 1fr">
        {(Areas) => (
          <>
            <Areas.Header>
              <Composition areas="label icon id">
                {(Header) => (
                  <>
                    <Header.Label>
                      <SoftLabel>Event</SoftLabel>
                    </Header.Label>
                    <Header.Icon justify="center">
                      <FontAwesomeIcon icon="calendar-check" color={Colors.GRAY1} size="2x" />
                    </Header.Icon>
                    <Header.Id justify="end">
                      <Text className={`${Classes.TEXT_MUTED} ${Classes.TEXT_SMALL}`}>{selectedEvent.id}</Text>
                    </Header.Id>
                  </>
                )}
              </Composition>
            </Areas.Header>
            <Areas.Name>
              <InputGroup readOnly={true} value={selectedEvent.name} />
            </Areas.Name>
            <Areas.Date>
              <InputGroup readOnly={true} value={format(selectedEvent.date, "M/d/yyyy")} />
            </Areas.Date>
            <Areas.Time>
              <InputGroup readOnly={true} value={format(selectedEvent.date, "h:mm b")} />
            </Areas.Time>
            <Areas.Venue>
              <InputGroup readOnly={true} value={selectedEvent.venue.name} />
            </Areas.Venue>
            <Areas.Location>
              <InputGroup readOnly={true} value={`${selectedEvent.venue.city}, ${selectedEvent.venue.region}`} />
            </Areas.Location>
            <Areas.Change align="end" justify="end" className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button icon="search" onClick={() => setSearchOpen(true)}>
                Change
              </Button>
            </Areas.Change>
          </>
        )}
      </Composition>
      {searchDialog}
    </Card>
  );
}
