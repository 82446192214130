import React from "react";
import { Classes, H5 } from "@blueprintjs/core";
import EventTable from "./EventTable";
import { Composition } from "atomic-layout";
import { IEvent } from "../../models/IEvent";
import { SearchInput } from "./SearchInput";
import { useQuery } from "react-query";
import { getRecentlyViewedEvents } from "../../nexusApi";

const areas = `
    search
    recent
`;

export interface ISearchHomeState {
  recentEvents: IEvent[] | undefined;
}

export function SearchHome() {
  const { data: recentEvents } = useQuery("recentEvents", () => getRecentlyViewedEvents().then((res) => res.data.data));

  return (
    <Composition areas={areas} gap="4rem" margin="1rem" width="calc(100vw - 3rem)" height="calc(100vh - 6rem)" templateRows="1fr 2fr">
      {({ Search, Recent }) => (
        <>
          <Search align="end" width="800px" justify="center">
            <SearchInput large={true} />
          </Search>
          <Recent width="800px" justify="center">
            {recentEvents && recentEvents.length > 0 && <H5 className={Classes.TEXT_MUTED}>Recently Viewed</H5>}
            <EventTable events={recentEvents} />
          </Recent>
        </>
      )}
    </Composition>
  );
}
