import { InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useNavigate } from "react-router";

export function SearchInput(props: { large: boolean; initialText?: string }) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(props.initialText);

  function handleSearchTextBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  return (
    <InputGroup
      type="search"
      placeholder="Search"
      leftIcon="search"
      large={props.large}
      value={searchText}
      onChange={handleSearchTextBoxChange}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          navigate(`/search/?q=${searchText}`);
        }
      }}
    />
  );
}
