import { Icon, Button, InputGroup, FormGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IExchangeIntegration } from "../../../../models/IIntegration";
import { addExchangeIntegration } from "../../../../slices/IntegrationsSlice";
import { Popover2, Classes } from "@blueprintjs/popover2";

export function TicketNetworkIntegrationMissing({ id, name }: IExchangeIntegration) {
  const dispatch = useDispatch();

  //TODO: Revert before production
  //const [inputValue, setInputValue] = useState('');
  const [inputValue, setInputValue] = useState("9749");

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (value: string) => {
    setIsOpen(false);

    dispatch(addExchangeIntegration(id, JSON.stringify({ brokerId: value }), "{}"));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <tr key={id}>
      <td>{name}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon="offline" />
      </td>
      <td style={{ textAlign: "center" }}>
        <Popover2
          content={
            <FormGroup label="TicketNetwork Broker ID" helperText="Input your Broker ID and press the Enter key" labelInfo="(required)" labelFor="brokerId-input">
              <InputGroup
                id="brokerId-input"
                type="text"
                placeholder="1234"
                autoFocus={true}
                value={inputValue}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSubmit(inputValue);
                  }
                }}
                rightElement={
                  <Button
                    minimal={true}
                    icon="arrow-right"
                    onClick={() => {
                      onSubmit(inputValue);
                    }}
                  />
                }
              />
            </FormGroup>
          }
          popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Button
            minimal={true}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Connect
          </Button>
        </Popover2>
      </td>
    </tr>
  );
}
