import React from "react";
import { Button } from "@blueprintjs/core";
import accounting from "accounting";
import { IWalletItem } from "../../models/IWalletItem";

export function WalletItem({ availableBalance, exchange }: IWalletItem) {
  const marketplaceIcon = "bank-account"; // In the future we will evaluate the marketplace and choose an appropriate svg

  return (
    <Button icon={marketplaceIcon} minimal={true}>
      {accounting.formatMoney(availableBalance ?? 0, "$", 0)}
    </Button>
  );
}
