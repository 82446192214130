import { Classes, Drawer, H2, Position } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../rootReducer";
import { ExchangeIntegrationsTable } from "./exchange/ExchangeIntegrationsTable";
import { SubscriptionList } from "./external/SubscriptionsList";
import { PointOfSaleIntegrationCard } from "./pos/PointOfSaleIntegrationCard";
import { IUserDrawerProps } from "../IUserDrawer";

const areas = `
    pos
    exchanges
    subscription
`;

export function IntegrationsDrawer({ onClose, isOpen, ...props }: IUserDrawerProps) {
  const [isDirty] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);

  const { pointOfSale, exchanges } = useSelector((state: RootState) => state.integrations);
  const { isSubscribed } = useSelector((state: RootState) => state.subscription);

  useEffect(() => {
    // If there is not POS or not a single active Exchange integration
    // Or no subscription, don't allow the drawer to close
    if (pointOfSale.name === "None" || isSubscribed === false || (exchanges !== undefined && exchanges.length > 0 && !exchanges.some((e) => e.status === "Active"))) {
      setKeepOpen(true);
    } else {
      setKeepOpen(false);
    }
  }, [pointOfSale, isSubscribed, exchanges]);

  function handleClose() {
    if (!keepOpen) {
      onClose();
    }
  }

  return (
    <Drawer
      icon="cog"
      title="Integrations"
      canOutsideClickClose={!isDirty}
      canEscapeKeyClose={!isDirty}
      position={Position.RIGHT}
      isOpen={isOpen || keepOpen}
      hasBackdrop={keepOpen}
      onClose={() => handleClose()}
      {...props}
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <Composition areas={areas} gap="2rem" justifyItems="center">
            {(Areas) => (
              <>
                <Areas.Pos>
                  <PointOfSaleIntegrationCard />
                </Areas.Pos>
                <Areas.Exchanges width="600px">
                  <H2>Exchanges</H2>
                  <ExchangeIntegrationsTable />
                </Areas.Exchanges>
                <Areas.Subscription width="600px">
                  <H2>Subscription</H2>
                  <SubscriptionList />
                </Areas.Subscription>
              </>
            )}
          </Composition>
        </div>
      </div>
    </Drawer>
  );
}
