import React from "react";
import { PosName } from "../../models/common/PosName";
import * as Logos from "./Logos";

export function PointOfSaleLogo(props: { name: PosName }) {
  let logo = Logos.TicketUtils;

  switch (props.name) {
    case "SkyBox":
      logo = Logos.SkyBox;
      break;
    case "PosNext":
      logo = Logos.PosNext;
      break;
    case "TicketUtils":
      logo = Logos.TicketUtils;
      break;
    case "None":
      logo = Logos.SkyBox;
      break;
  }

  return <img src={logo.src} alt={logo.alt} style={{ maxHeight: "150px", maxWidth: "150px" }} />;
}
