import axios from "axios";
import { IResponseBase, IResponseBaseCollection, IResponseBaseWithData } from "./models/common/IResponseBase";
import { IDeliveryMethod } from "./models/IDeliveryMethod";
import { IEvent } from "./models/IEvent";
import { IListing } from "./models/IListing";
import { IExchangeIntegration, IPosIntegration } from "./models/IIntegration";
import { IPosCustomer } from "./models/IPosCustomer";
import { IWalletItem } from "./models/IWalletItem";
import { IPurchaseTicketsRequest } from "./models/IPurchaseTicketRequest";
import { ISubscriptionPlan } from "./models/ISubscriptionPlan";
import { ISubscription } from "./models/ISubscription";
import { IExchangeId } from "./models/IExchangeId";
import { fromEventDate, toEventDate } from "./utilities/DateTimeHelpers";
import { IPurchaseResponse } from "./models/IPurchaseResponse";
import { IPosPurchase } from "./models/IPosPurchase";
import { IMonitor } from "./models/IMonitor";
import { IMonitorWithSummary } from "./models/IMonitorWithSummary";
import { IMonitorWithSnapshots } from "./models/IMonitorWithSnapshots";

axios.defaults.baseURL = process.env.REACT_APP_NEXUS_API_URL;
axios.defaults.validateStatus = (status: number) => status < 400; // 304 returns error (dumb)

export function setAxiosTokenInterceptor(accessToken: string) {
  axios.interceptors.request.use((req) => {
    if (accessToken) {
      req.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    return req;
  });
}

export async function initUser() {
  return await axios.post<IResponseBase>("/user");
}

export async function getWallet() {
  return await axios.get<IResponseBaseCollection<IWalletItem>>("/exchange/wallet");
}

export async function lookupCustomer(email: string) {
  return await axios.get<IResponseBaseWithData<IPosCustomer>>("/pos/customers/lookup", { params: { email } });
}

export async function getCustomers() {
  return await axios.get<IResponseBaseCollection<IPosCustomer>>("/pos/customers");
}

export async function getDefaultCustomer() {
  return await axios.get<IResponseBaseWithData<IPosCustomer>>("/pos/customers/default");
}

export async function lookupEvent(name: string, eventDateTime: Date, venueName: string, id: string | number | undefined) {
  const response = await axios.get<IResponseBaseWithData<IEvent>>("/pos/events/lookup", {
    params: {
      id,
      name,
      eventDateTime: toEventDate(eventDateTime),
      venueName,
    },
  });

  if (response?.data?.data?.date) {
    response.data.data.date = fromEventDate(response.data.data.date);
  }

  return response;
}

export async function searchPosEvents(query: string, dateFrom: Date, dateTo: Date) {
  const response = await axios.get<IResponseBaseCollection<IEvent>>("/pos/events", {
    params: {
      query,
      dateFrom: toEventDate(dateFrom),
      dateTo: toEventDate(dateTo),
    },
  });

  response?.data?.data?.forEach((e) => (e.date = fromEventDate(e.date)));

  return response;
}

export async function searchCatalogEvents(query: string) {
  const response = await axios.get<IResponseBaseCollection<IEvent>>("/catalog/events/search", { params: { query } });

  response?.data?.data?.forEach((e) => (e.date = fromEventDate(e.date)));

  return response;
}

export async function getRecentlyViewedEvents() {
  const response = await axios.get<IResponseBaseCollection<IEvent>>("/catalog/events/recentlyViewed");

  response?.data?.data?.forEach((e) => (e.date = fromEventDate(e.date)));

  return response;
}

export async function getEvent(eventId: number) {
  const response = await axios.get<IResponseBaseWithData<IEvent>>(`/catalog/events/${eventId}`);

  if (response?.data?.data?.date) {
    response.data.data.date = fromEventDate(response.data.data.date);
  }

  return response;
}

export async function getEventMappings(eventId: number) {
  return await axios.get<IResponseBaseCollection<IExchangeId>>(`/exchange/events/${eventId}/mappings`);
}

export async function postEventMapping(params: { eventId: number; exchangeId: IExchangeId }) {
  return await axios.post<IResponseBase>(`/exchange/events/${params.eventId}/mappings`, params.exchangeId);
}

export async function getEventListings(eventId: number) {
  return await axios.get<IResponseBaseCollection<IListing>>(`/exchange/events/${eventId}/listings`);
}

export async function getEventListingDeliveryMethods(eventId: number, exchangeId: number, listingId: number) {
  return await axios.get<IResponseBaseCollection<IDeliveryMethod>>(`/exchange/events/${eventId}/exchange/${exchangeId}/listings/${listingId}/deliveryMethods`);
}

export async function getExchangeIntegrations() {
  return await axios.get<IResponseBaseCollection<IExchangeIntegration>>("/exchange/integrations");
}

export async function postExchangeIntegration(exchangeId: number, connectionJson: string, settingsJson: string) {
  return await axios.post<IResponseBaseWithData<IExchangeIntegration>>("/exchange/integrations", {
    exchange: exchangeId,
    connectionJson,
    settingsJson,
  });
}

export async function deleteExchangeIntegration(exchangeId: number) {
  return await axios.delete<IResponseBase>(`/exchange/integrations/${exchangeId}`);
}

export async function getPointOfSaleIntegration() {
  return await axios.get<IResponseBaseWithData<IPosIntegration>>("/pos/integration");
}

export async function postPointOfSaleIntegration(posId: number, connectionJson: string, settingsJson: string) {
  return await axios.post<IResponseBaseWithData<IPosIntegration>>("/pos/integration", {
    id: posId,
    connectionJson,
    settingsJson,
  });
}

export async function deletePointOfSaleIntegration() {
  return await axios.delete<IResponseBase>("/pos/integration");
}

export async function postPurchaseTickets({ exchangeId, purchase }: IPurchaseTicketsRequest) {
  return await axios.post<IResponseBaseWithData<IPurchaseResponse>>(`/exchange/${exchangeId}/orders`, purchase);
}

export async function postPosPurchase(purchase: IPosPurchase) {
  return await axios.post<IResponseBaseWithData<IPurchaseResponse>>("/pos/purchases", purchase);
}

export async function getSubscriptionPlans() {
  return await axios.get<IResponseBaseCollection<ISubscriptionPlan>>("/subscription/plans");
}

export async function getSubscription() {
  return await axios.get<IResponseBaseWithData<ISubscription>>("/subscription");
}

export async function postSubscription(subscriptionId: string) {
  return await axios.post<IResponseBaseWithData<ISubscription>>("/subscription", { subscriptionId });
}

export async function getPurchases() {
  const response = await axios.get<IResponseBaseCollection<IPurchaseResponse>>("/purchases");

  response?.data?.data?.forEach((p) => {
    p.listing.event.dateTime = fromEventDate(p.listing.event.dateTime);
    p.createdAt = new Date(p.createdAt);
    p.updatedAt = new Date(p.updatedAt);

    if (p.listing.inHandDate) {
      p.listing.inHandDate = fromEventDate(p.listing.inHandDate);
    }
  });

  return response;
}

export async function postFavoriteEvent(eventId: number) {
  return await axios.post<IResponseBase>("/user/favorites", { eventId });
}

export async function deleteFavoriteEvent(eventId: number) {
  return await axios.delete(`/user/favorites/${eventId}`);
}

export async function postMonitor(monitor: IMonitor) {
  return await axios.post<IResponseBaseWithData<IMonitor>>("/monitors", monitor);
}

export async function putMonitor(monitor: IMonitor) {
  return await axios.put<IResponseBaseWithData<IMonitor>>(`/monitors/${monitor.guid}`, monitor);
}

export async function deleteMonitor(monitorGuid: string) {
  return await axios.delete<IResponseBase>(`/monitors/${monitorGuid}`);
}

export async function getMonitorsByEventId(eventId: number) {
  return await axios.get<IResponseBaseCollection<IMonitorWithSummary>>(`/monitors/event/${eventId}`);
}

export async function getMonitor(monitorGuid: string) {
  return await axios.get<IResponseBaseWithData<IMonitorWithSnapshots>>(`/monitors/${monitorGuid}`);
}
