import { Icon, Button } from "@blueprintjs/core";
import React from "react";
import { useDispatch } from "react-redux";
import { IExchangeIntegration } from "../../../../models/IIntegration";
import { addExchangeIntegration } from "../../../../slices/IntegrationsSlice";

export function GenericIntegrationMissing({ id, name }: IExchangeIntegration) {
  const dispatch = useDispatch();

  return (
    <tr key={id}>
      <td>{name}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon="offline" />
      </td>
      <td style={{ textAlign: "center" }}>
        <Button minimal={true} onClick={() => dispatch(addExchangeIntegration(id, "{}", "{}"))}>
          Connect
        </Button>
      </td>
    </tr>
  );
}
