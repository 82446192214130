import { useParams } from "react-router-dom";

// Solution borrowed from here: https://github.com/ReactTraining/react-router/issues/7380

interface IProps {
  children: React.ReactNode;
}

export function ComponentWithParams({ children }: IProps) {
  const params = useParams();

  if (typeof children === "function") {
    const ret = children(params);
    return ret || null;
  } else {
    return children;
  }
}
