import { Alignment, Button, InputGroup, Navbar } from "@blueprintjs/core";
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";
import { Composition } from "atomic-layout";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IExchangeIntegration } from "../../models/IIntegration";
import { RootState } from "../../rootReducer";
import { getDateInputProps } from "../../utilities/DateTimeHelpers";
import { IMenuPickerItem, MenuPickerButton } from "../shared/MenuPickerButton";

const areas = "search dates exchanges clear";

const exchangeItems = (items: IExchangeIntegration[] | undefined): IMenuPickerItem[] => {
  if (!items) {
    return [];
  }

  return items
    .filter((x) => x.status === "Active")
    .map((x) => {
      return {
        id: x.id,
        text: x.name,
        icon: undefined,
      };
    });
};

export interface IPurchasesGridFilterState {
  dateRange?: DateRange;
  textFilter?: string;
  exchangeId?: number;
}

export interface IPurchaseGridProps {
  onFilterChange(params: IPurchasesGridFilterState): void;

  initialTextFilter?: string;
}

export function PurchasesFilter({ onFilterChange, initialTextFilter }: IPurchaseGridProps) {
  const [textFilter, setTextFilter] = useState("");
  const [dateRange, setDateRange] = useState<DateRange>();
  const [exchangeId, setExchangeId] = useState<number>();
  const [exchangeItem, setExchangeItem] = useState<IMenuPickerItem>();

  const { exchanges } = useSelector((state: RootState) => state.integrations);

  useEffect(() => {
    if (initialTextFilter) {
      setTextFilter(initialTextFilter);
      onFilterChange({
        textFilter: initialTextFilter,
        dateRange: undefined,
        exchangeId: undefined,
      });
    }
  }, [initialTextFilter, setTextFilter, onFilterChange]);

  function handleFilterTextBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTextFilter(e.target.value);
    onFilterChange({ textFilter: e.target.value, dateRange, exchangeId });
  }

  function handleDateRangeChange(selectedRange: DateRange | undefined) {
    setDateRange(selectedRange);
    onFilterChange({ textFilter, dateRange: selectedRange, exchangeId });
  }

  function handleExchangeFilterChange(item: IMenuPickerItem | undefined) {
    setExchangeId(item?.id);
    setExchangeItem(item);
    onFilterChange({ textFilter, dateRange, exchangeId: item?.id });
  }

  function resetFilters() {
    setTextFilter("");
    setDateRange(undefined);
    setExchangeId(undefined);
    setExchangeItem(undefined);
    onFilterChange({
      textFilter: "",
      dateRange: undefined,
      exchangeId: undefined,
    });
  }

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Composition areas={areas} gap="1rem">
          {({ Search, Dates, Exchanges, Clear }) => (
            <>
              <Search>
                <InputGroup type="search" placeholder="Search" leftIcon="search" onChange={handleFilterTextBoxChange} value={textFilter} />
              </Search>
              <Dates>
                <DateRangeInput
                  {...getDateInputProps("M/d/yyyy")}
                  allowSingleDayRange={true}
                  startInputProps={{
                    leftIcon: "calendar",
                    style: { width: "16ch" },
                  }}
                  endInputProps={{
                    leftIcon: "calendar",
                    style: { width: "16ch" },
                  }}
                  value={dateRange}
                  onChange={handleDateRangeChange}
                />
              </Dates>
              <Exchanges>
                <MenuPickerButton items={exchangeItems(exchanges)} defaultText="Any Exchange" selectedItem={exchangeItem} onSelectedItemChange={handleExchangeFilterChange} />
              </Exchanges>
              <Clear>{(textFilter || dateRange || exchangeId) && <Button icon="cross" onClick={() => resetFilters()} />}</Clear>
            </>
          )}
        </Composition>
      </Navbar.Group>
    </Navbar>
  );
}
