import { Button, H5, Intent } from "@blueprintjs/core";
import React from "react";
import { Popover2, Classes, Popover2SharedProps } from "@blueprintjs/popover2";

export interface IPopoverConfirmDangerProps extends Popover2SharedProps<any> {
  titleText: string;
  messageText: string;
  confirmButtonLabel: string;
  children: JSX.Element;

  onConfirmClick(): void;
}

export function PopoverConfirmDanger({ titleText, messageText, confirmButtonLabel, onConfirmClick, children, ...props }: IPopoverConfirmDangerProps) {
  const content = (
    <div>
      <H5>{titleText}</H5>
      <p>{messageText}</p>
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
        <Button className={Classes.POPOVER2_DISMISS} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button intent={Intent.DANGER} className={Classes.POPOVER2_DISMISS} onClick={onConfirmClick}>
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover2 popoverClassName={Classes.POPOVER2_CONTENT_SIZING} content={content} {...props}>
      {children}
    </Popover2>
  );
}
