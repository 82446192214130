import {Button, HTMLInputProps, InputGroup, InputGroupProps2, Intent} from "@blueprintjs/core";
import React, { useState } from "react";
import {Tooltip2} from "@blueprintjs/popover2";

export function UsernameInput(props: InputGroupProps2 & HTMLInputProps) {
  return <InputGroup leftIcon="person" placeholder="Username" type="text" {...props} />;
}

export function PasswordInput(props: InputGroupProps2 & HTMLInputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const lockButton = (
    <Tooltip2 content={`${showPassword ? "Hide" : "Show"} Password`}>
      <Button icon={showPassword ? "unlock" : "lock"} intent={Intent.WARNING} minimal={true} onClick={() => setShowPassword(!showPassword)} />
    </Tooltip2>
  );

  return <InputGroup leftIcon="key" placeholder="Password" type={showPassword ? "text" : "password"} {...props} rightElement={lockButton} />;
}
