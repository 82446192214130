import React from "react";
import { IListing } from "../../models/IListing";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { currencyFormatter } from "../../utilities/Formatters";
import { NaturalComparator } from "../shared/Comparators";
import logitixIcon from "../../images/logitixlive-favicon.png";
import stubhubIcon from "../../images/stubhub-favicon.png";
import ticketevolutionIcon from "../../images/ticketevolution-favicon.png";
import ticketmasterIcon from "../../images/ticketmaster-favicon.png";
import tickpickIcon from "../../images/tickpick-favicon.png";
import ticketnetworkIcon from "../../images/ticketnetwork-favicon.png";

export interface IListingsGridProps {
  listings?: IListing[];

  onSelectedListingChange(listing: IListing | undefined): void;
}

function exchangeCellRenderer(params: ICellRendererParams) {
  // Set the source icon based on the exchange
  let sourceIcon = "";

  switch (params.data.exchange.id) {
    case 2:
      sourceIcon = stubhubIcon;
      break;
    case 3:
      sourceIcon = ticketmasterIcon;
      break;
    case 4:
      sourceIcon = ticketevolutionIcon;
      break;
    case 5:
      sourceIcon = ticketnetworkIcon;
      break;
    case 6:
      sourceIcon = logitixIcon;
      break;
    case 7:
      sourceIcon = tickpickIcon;
      break;
  }

  return <img src={sourceIcon} alt={params.data.exchange.name} style={{ width: "2ch", height: "2ch" }} />;
}

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "id", hide: true },
  {
    headerName: "Section",
    field: "section",
    flex: 5,
    comparator: NaturalComparator,
  },
  {
    headerName: "Row",
    field: "row",
    width: 100,
    cellStyle: { textAlign: "center" },
    comparator: NaturalComparator,
  },
  {
    headerName: "Qty",
    field: "quantity",
    width: 100,
    cellStyle: { textAlign: "center" },
  },
  //{ headerName: "", field: "extra", sortable: false, minWidth: 10, flex: 1, cellRendererFramework: extraCellRenderer },
  {
    headerName: "",
    field: "exchange",
    minWidth: 10,
    flex: 1,
    cellRendererFramework: exchangeCellRenderer,
  },
  {
    headerName: "Price",
    field: "unitPrice",
    width: 150,
    cellStyle: { textAlign: "right" },
    sort: "asc",
    valueFormatter: currencyFormatter,
  },
];

export function ListingsGrid({ listings, onSelectedListingChange }: IListingsGridProps) {
  function handleSelectionChanged(e: SelectionChangedEvent) {
    const selectedRows = e.api.getSelectedRows();

    if (!selectedRows) {
      onSelectedListingChange(undefined);
    } else {
      const selectedRow = selectedRows[0];

      if (selectedRow) {
        onSelectedListingChange(selectedRow);
      } else {
        // This can happen if the user changes their filters and the selected row is no longer in the array
        onSelectedListingChange(undefined);
      }
    }
  }

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", minWidth: "470px" }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={listings}
        defaultColDef={{ sortable: true, resizable: true }}
        suppressCellSelection={true}
        onSelectionChanged={handleSelectionChanged}
        immutableData={true}
        getRowNodeId={(data) => data.id}
      />
    </div>
  );
}
