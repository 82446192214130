import React from "react";
import { Navbar, Alignment, Button, Tooltip, NavbarHeading } from "@blueprintjs/core";
import UserMenu from "./UserMenu";
import { Wallet } from "./Wallet";
import { Link, useLocation } from "react-router-dom";
import { SearchInput } from "../search/SearchInput";
import { PageTitle } from "./PageTitle";
import queryString from "query-string";

export function TopBar() {
  const location = useLocation();

  let isSearchVisible = location.pathname !== "/";

  let searchBarText = "";

  if (location.pathname === "/search/") {
    searchBarText = queryString.parse(location.search).q as string;
  }

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button icon="globe-network" minimal={true}>
            NEXUS
          </Button>
        </Link>
        <NavbarHeading>v{process.env.REACT_APP_NEXUS_VERSION}</NavbarHeading>
        <PageTitle />
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        {isSearchVisible && (
          <>
            <SearchInput large={false} initialText={searchBarText} />
            <Navbar.Divider />
          </>
        )}
        <Wallet />
        <Navbar.Divider />
        <Tooltip content="Monitors">
          <Button
            icon="pulse"
            minimal={true}
            onClick={() => {
              throw new Error("test");
            }}
          />
        </Tooltip>
        <Tooltip content="Notifications">
          <Button icon="notifications" minimal={true} />
        </Tooltip>
        <Tooltip content="Purchases">
          <Link to="/purchases">
            <Button icon="briefcase" minimal={true} />
          </Link>
        </Tooltip>
        <UserMenu />
      </Navbar.Group>
    </Navbar>
  );
}
