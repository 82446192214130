import { Button, Classes, InputGroup, Intent, Label } from "@blueprintjs/core";
import { AgGridReact } from "ag-grid-react";
import { Composition } from "atomic-layout";
import React, { useEffect, useState } from "react";
import { ColDef, GridApi, GridReadyEvent, RowDataChangedEvent, SelectionChangedEvent } from "ag-grid-community";
import { useQuery } from "react-query";
import { searchPosEvents } from "../../nexusApi";
import { IEvent } from "../../models/IEvent";
import { shortDateFormatter, shortDayFormatter, shortEventTimeFormatter } from "../../utilities/Formatters";
import { DateFilter } from "../shared/DateFilter";
import { addYears, subYears } from "date-fns";

const areas = `
    filters
    grid
`;

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "id", hide: true },
  { headerName: "Name", field: "name" },
  {
    headerName: "Day",
    valueGetter: (params) => shortDayFormatter(params.data.date),
  },
  { headerName: "Date", field: "date", valueFormatter: shortDateFormatter },
  {
    headerName: "Time",
    valueGetter: (params) => shortEventTimeFormatter(params.data.date),
  },
  { headerName: "Venue", valueGetter: (params) => params.data.venue.name },
  {
    headerName: "City",
    flex: 1,
    valueGetter: (params) => params.data.venue.city,
  },
];

export interface IEventSearchProps {
  onCancel(): void;

  onSelect(event: IEvent): void;

  initialQuery: string;
  initialDateFrom: Date;
  initialDateTo: Date;
}

export function EventSearchDialog({ onCancel, onSelect, initialQuery, initialDateFrom, initialDateTo }: IEventSearchProps) {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [selectedEvent, setselectedEvent] = useState<IEvent>();
  const [query, setQuery] = useState(initialQuery);
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);
  const [searchInput, setSearchInput] = useState(initialQuery);
  const [dateFromInput, setDateFromInput] = useState(initialDateFrom);
  const [dateToInput, setDateToInput] = useState(initialDateTo);

  const { refetch } = useQuery(
    ["events", query, dateFrom, dateTo],
    () => searchPosEvents(query, dateFrom, dateTo).then((res) => (res && gridApi ? gridApi.setRowData(res.data.data) : {})),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (gridApi) {
      refetch();
    }
  }, [refetch, gridApi]);

  function handleGridReady(e: GridReadyEvent) {
    setGridApi(e.api);
  }

  function handleRowDataChanged(e: RowDataChangedEvent) {
    e.columnApi.autoSizeAllColumns(false);
  }

  function handleSelectionChanged(e: SelectionChangedEvent) {
    const selectedRows = e.api.getSelectedRows();

    if (selectedRows) {
      setselectedEvent(selectedRows[0]);
    } else {
      setselectedEvent(undefined);
    }
  }

  function handleSearchTextBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchInput(e.target.value);
  }

  async function handleSearchClick() {
    // HACKHACK I'm not sure why but since these calls appear to be asynchronous to update state
    // I have to await them otherwise the refetch won't have the new values
    await setQuery(searchInput);
    await setDateFrom(dateFromInput);
    await setDateTo(dateToInput);
    gridApi?.showLoadingOverlay();
    await refetch();
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Composition areas={areas} gap="1rem">
          {({ Filters, Grid }) => (
            <>
              <Filters>
                <Composition areas={"search dateFrom dateTo submit"} gap="1rem" templateCols="auto auto auto 1fr">
                  {(FilterAreas) => (
                    <>
                      <FilterAreas.Search>
                        <Label>
                          Search
                          <InputGroup type="search" leftIcon="search" placeholder="Search" value={searchInput} style={{ width: "50ch" }} onChange={handleSearchTextBoxChange} />
                        </Label>
                      </FilterAreas.Search>
                      <FilterAreas.DateFrom>
                        <DateFilter
                          labelText="Start Date"
                          placeholder="Start Date"
                          minDate={subYears(initialDateFrom, 1)}
                          maxDate={addYears(initialDateTo, 1)}
                          value={dateFromInput}
                          onChange={(selectedDate, isUserChange) => {
                            if (isUserChange) {
                              setDateFromInput(selectedDate);
                            }
                          }}
                        />
                      </FilterAreas.DateFrom>
                      <FilterAreas.DateTo>
                        <DateFilter
                          labelText="End Date"
                          placeholder="End Date"
                          minDate={subYears(initialDateFrom, 1)}
                          maxDate={addYears(initialDateTo, 1)}
                          value={dateToInput}
                          onChange={(selectedDate, isUserChange) => {
                            if (isUserChange) {
                              setDateToInput(selectedDate);
                            }
                          }}
                        />
                      </FilterAreas.DateTo>
                      <FilterAreas.Submit align="end">
                        <Label>
                          {" "}
                          {/* wrapping in a label just to get the spacing / alignment right */}
                          <Button icon="search" onClick={handleSearchClick}>
                            Search
                          </Button>
                        </Label>
                      </FilterAreas.Submit>
                    </>
                  )}
                </Composition>
              </Filters>
              <Grid>
                <div className="ag-theme-alpine" style={{ height: "600px" }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    //rowData={rowData}
                    defaultColDef={{ sortable: true, resizable: true }}
                    suppressCellSelection={true}
                    rowSelection="single"
                    immutableData={true}
                    getRowNodeId={(data) => data.id}
                    onGridReady={handleGridReady}
                    onSelectionChanged={handleSelectionChanged}
                    onRowDataChanged={handleRowDataChanged}
                  />
                </div>
              </Grid>
            </>
          )}
        </Composition>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button intent={Intent.PRIMARY} disabled={!selectedEvent} onClick={() => onSelect(selectedEvent!)}>
            Select
          </Button>
        </div>
      </div>
    </>
  );
}
