import React, { useState } from "react";
import { Button, InputGroup, ControlGroup, ButtonProps } from "@blueprintjs/core";
import { Popover2, Classes as Popover2Classes } from "@blueprintjs/popover2";

export interface IPopoverTextInputButtonProps extends ButtonProps {
  buttonPlaceholder: string;
  buttonPrefix: string;
  inputPlaceholder: string;
  value: string;

  onChange?(newValue: string): void;
}

export function PopoverTextInputButton({ buttonPlaceholder, buttonPrefix, inputPlaceholder, value, onChange, ...props }: IPopoverTextInputButtonProps) {
  const [inputValue, setInputValue] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = (oldValue?: string, newValue?: string, onChange?: Function) => {
    if (oldValue === newValue) {
      setIsOpen(false);
      return;
    }

    setIsOpen(false);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <Popover2
      popoverClassName={Popover2Classes.POPOVER2_CONTENT_SIZING}
      placement={"bottom"}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      content={
        <InputGroup
          type="text"
          placeholder={inputPlaceholder}
          defaultValue={value}
          autoFocus={true}
          onChange={handleChange}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit(value, inputValue, onChange);
            }
          }}
          rightElement={
            <Button
              minimal={true}
              icon="arrow-right"
              onClick={() => {
                onSubmit(value, inputValue, onChange);
              }}
            />
          }
        />
      }
    >
      <ControlGroup>
        <Button
          rightIcon="caret-down"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {value.length === 0 ? buttonPlaceholder : `${buttonPrefix}${value}`}
          {/* {props} // This is causing problems for some reason. */}
        </Button>
        {value.length > 0 && (
          <Button
            icon="cross"
            onClick={() => {
              setIsOpen(false);
              onSubmit(value, "", onChange);
            }}
          />
        )}
      </ControlGroup>
    </Popover2>
  );
}
