import { IPosIntegration, IExchangeIntegration } from "../models/IIntegration";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import {
  postPointOfSaleIntegration,
  getPointOfSaleIntegration,
  deletePointOfSaleIntegration,
  getExchangeIntegrations,
  postExchangeIntegration,
  deleteExchangeIntegration,
} from "../nexusApi";
import { fetchWallet } from "./WalletSlice";

export interface IIntegrationsState {
  pointOfSale: IPosIntegration;
  exchanges: IExchangeIntegration[];
}

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    pointOfSale: {} as IPosIntegration,
    exchanges: [],
  } as IIntegrationsState,
  reducers: {
    getPointOfSaleSuccess(state, action: PayloadAction<IPosIntegration>) {
      state.pointOfSale = action.payload;
    },
    getPointOfSaleFailure(state, action: PayloadAction<string>) {
      state.pointOfSale.id = 0;
      state.pointOfSale.name = "None";
      state.pointOfSale.status = "Error";
    },
    getExchangeIntegrationsSuccess(state, action: PayloadAction<IExchangeIntegration[]>) {
      state.exchanges = action.payload;
    },
  },
});

export const { getPointOfSaleSuccess, getPointOfSaleFailure, getExchangeIntegrationsSuccess } = integrationsSlice.actions;

export default integrationsSlice.reducer;

export const fetchPointOfSaleIntegration = (): AppThunk => async (dispatch) => {
  try {
    const response = await getPointOfSaleIntegration();
    dispatch(getPointOfSaleSuccess(response.data.data));
  } catch (err) {
    getPointOfSaleFailure(err.toString());
  }
};

export const addPointOfSaleIntegration = (posId: number, connectionJson: string, settingsJson: string): AppThunk => async (dispatch) => {
  try {
    const response = await postPointOfSaleIntegration(posId, connectionJson, settingsJson);
    dispatch(getPointOfSaleSuccess(response.data.data));
  } catch (err) {
    getPointOfSaleFailure(err.toString());
  }
};

export const removePointOfSaleIntegration = (): AppThunk => async (dispatch) => {
  try {
    await deletePointOfSaleIntegration();
    dispatch(fetchPointOfSaleIntegration());
  } catch (err) {
    getPointOfSaleFailure(err.toString());
  }
};

export const fetchExchangeIntegrations = (): AppThunk => async (dispatch) => {
  try {
    const response = await getExchangeIntegrations();
    dispatch(getExchangeIntegrationsSuccess(response.data.data));
  } catch (err) {
    console.log(err); // For now
  }
};

export const addExchangeIntegration = (exchangeId: number, connectionJson: string, settingsJson: string): AppThunk => async (dispatch) => {
  try {
    await postExchangeIntegration(exchangeId, connectionJson, settingsJson);
    const response = await getExchangeIntegrations(); // HACKAHCK - Only doing this because I don't know how I can call fetchExchangeIntegrations from here
    dispatch(getExchangeIntegrationsSuccess(response.data.data));
    dispatch(fetchWallet());
  } catch (err) {
    console.log(err); // For now
  }
};

export const removeExchangeIntegration = (exchangeId: number): AppThunk => async (dispatch) => {
  try {
    await deleteExchangeIntegration(exchangeId);
    const response = await getExchangeIntegrations();
    dispatch(getExchangeIntegrationsSuccess(response.data.data));
    dispatch(fetchWallet());
  } catch (err) {
    console.log(err); // For now
  }
};
