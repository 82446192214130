import { Icon, Button } from "@blueprintjs/core";
import React from "react";
import { IExchangeIntegration } from "../../../../models/IIntegration";
import { ApexIntegrationMissing } from "./ApexIntegrationMissing";
import { TicketNetworkIntegrationMissing } from "./TicketNetworkIntegrationMissing";
import { GenericIntegrationMissing } from "./GenericIntegrationMissing";
import { TicketEvolutionIntegrationMissing } from "./TicketEvolutionIntegrationMissing";

export function NotConnectedIntegration({ id, name }: IExchangeIntegration) {
  if (name === "TicketNetwork") {
    return <TicketNetworkIntegrationMissing key={id} id={id} name={name} />;
  }

  if (name === "Logitix Live") {
    return <ApexIntegrationMissing key={id} id={id} name={name} />;
  }

  if (name === "Ticket Evolution") {
    return <TicketEvolutionIntegrationMissing key={id} id={id} name={name} />;
  }

  if (name === "Ticketmaster" || name === "TickPick" || name === "StubHub") {
    return <GenericIntegrationMissing key={id} id={id} name={name} />;
  }

  return (
    <tr key={id}>
      <td>{name}</td>
      <td style={{ textAlign: "center" }}>
        <Icon icon="offline" />
      </td>
      <td style={{ textAlign: "center" }}>
        <Button minimal={true}>Not Supported</Button>
      </td>
    </tr>
  );
}
