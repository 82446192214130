export enum ExchangeEnum {
  None = 0,
  "Vivid Seats",
  StubHub,
  Ticketmaster,
  "Ticket Evolution",
  TicketNetwork,
  "Logitix Live",
  TickPick,
}
