import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@blueprintjs/core";
import { Position } from "@blueprintjs/core";
import { ExchangeEnum } from "../../models/enums/ExchangeEnum";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";

export interface ListingsFilterAdvancedProps {
  exchanges: ExchangeEnum[];
  onExchangesFilterChange: (allowedMarketplaces: number[]) => void;
}

export function ListingsFilterAdvanced({ exchanges, onExchangesFilterChange }: ListingsFilterAdvancedProps) {
  const [marketplacesEnabled, setMarketplacesEnabled] = useState(
    exchanges?.map((exchange) => {
      return { exchange, enabled: true };
    }) ?? []
  );

  const toggleMarketplaceEnabled = (exchange: ExchangeEnum) => {
    let copy = marketplacesEnabled.map((x) => x);
    let selected = copy.find((m) => m.exchange === exchange);
    selected!.enabled = !selected?.enabled;
    setMarketplacesEnabled(copy);

    onExchangesFilterChange(copy.filter((m) => m.enabled).map((m) => m.exchange));
  };

  return (
    <Popover2
      content={
        <Menu>
          <MenuItem icon="property" text="Traits"></MenuItem>
          <MenuItem icon="airplane" text="Delivery"></MenuItem>
          <MenuItem icon="shop" text="Marketplace">
            {marketplacesEnabled
              .filter((m) => m.exchange !== ExchangeEnum["Vivid Seats"])
              .map((m, i) => {
                return (
                  <Tooltip2 key={i} content={`Click to ${m.enabled ? "disable" : "enable"}`} position={Position.RIGHT}>
                    <MenuItem
                      key={i}
                      text={ExchangeEnum[m.exchange]}
                      shouldDismissPopover={false}
                      onClick={() => toggleMarketplaceEnabled(m.exchange)}
                      style={!m.enabled ? { textDecoration: "line-through" } : undefined}
                    />
                  </Tooltip2>
                );
              })}
          </MenuItem>
        </Menu>
      }
    >
      <Button rightIcon="caret-down">Advanced</Button>
    </Popover2>
  );
}
