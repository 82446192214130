import React, { useState, ChangeEvent } from "react";
import { InputGroupProps2, Button, InputGroup } from "@blueprintjs/core";

export interface TextInputProps extends Omit<InputGroupProps2, "rightElement"> {
  onClearClick?: () => void;
}

export function TextInputClearable({ onChange, onSubmit, onClearClick, value, ...props }: TextInputProps) {
  const [inputText, setInputText] = useState<string>();

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (value) {
      setInputText(event.target.value);
    }
    if (onChange) {
      onChange(event);
    }
  }

  function handleXClick() {
    if (value) {
      setInputText("");
    }
    if (onClearClick) {
      onClearClick();
    }
  }

  const showX = (inputText !== undefined && inputText.length > 0) || (value !== undefined && value.length > 0);

  const xButton = (
    <Button icon="cross" onClick={handleXClick} minimal={true} />
  );

  return (
    <InputGroup onChange={handleInputChange} value={value ? value : inputText} rightElement={showX ? xButton : undefined} {...props} />
  );
}



