import { HTMLTable, Icon, Intent, Button } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../rootReducer";
import { fetchExchangeIntegrations, removeExchangeIntegration } from "../../../../slices/IntegrationsSlice";
import { PopoverConfirmDanger } from "../../../shared/PopoverConfirmDanger";
import { NotConnectedIntegration } from "./NotConnectedIntegration";

export function ExchangeIntegrationsTable() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchExchangeIntegrations());
  }, [dispatch]);

  const { exchanges } = useSelector((state: RootState) => state.integrations);

  return (
    <HTMLTable interactive={false} striped={true} bordered={true} style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Exchange</th>
          <th style={{ textAlign: "center" }}>Status</th>
          <th style={{ textAlign: "center" }}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {exchanges.map((exchange) => {
          if (exchange.status === "Not Connected") {
            return <NotConnectedIntegration key={exchange.id} {...exchange} />;
          }

          return (
            <tr key={exchange.id}>
              <td>{exchange.name}</td>
              <td style={{ textAlign: "center" }}>
                <Icon icon="tick" intent={exchange.status === "Active" ? Intent.SUCCESS : Intent.WARNING} />
              </td>
              <td style={{ textAlign: "center" }}>
                <PopoverConfirmDanger
                  titleText="Confirm disconnect"
                  messageText="Are you sure you want to disconnect this exchange?"
                  confirmButtonLabel="Disconnect"
                  onConfirmClick={() => dispatch(removeExchangeIntegration(exchange.id))}
                >
                  <Button minimal={true}>Disconnect</Button>
                </PopoverConfirmDanger>
              </td>
            </tr>
          );
        })}
      </tbody>
    </HTMLTable>
  );
}
