import { Button, Classes, InputGroup, Intent } from "@blueprintjs/core";
import { AgGridReact } from "ag-grid-react";
import { Composition } from "atomic-layout";
import React, { useEffect, useState } from "react";
import { IPosCustomer } from "../../models/IPosCustomer";
import { ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import { formatAddress } from "../../utilities/Formatters";
import { useQuery } from "react-query";
import { getCustomers } from "../../nexusApi";

const areas = `
    search
    grid
`;

const columnDefs: ColDef[] = [
  { headerName: "Id", field: "id", hide: true },
  {
    headerName: "Name",
    valueGetter: (params) => (params.data.firstName ?? "") + " " + (params.data.lastName ?? ""),
  },
  { headerName: "Email", field: "email" },
  { headerName: "Phone", field: "phone", width: 130 },
  {
    headerName: "Address",
    flex: 1,
    valueGetter: (params) => formatAddress(params.data.address1, params.data.address2, params.data.city, params.data.region, "", params.data.postalCode),
  },
];

export interface ICustomerSearchProps {
  onCancel(): void;

  onSelect(customer: IPosCustomer): void;
}

export function CustomerSearchDialog({ onCancel, onSelect }: ICustomerSearchProps) {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [searchText, setSearchText] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<IPosCustomer>();

  const { data: rowData } = useQuery("customers", () => getCustomers().then((res) => res.data.data));

  useEffect(() => {
    gridApi?.setQuickFilter(searchText);
  }, [searchText, gridApi]);

  function handleGridReady(e: GridReadyEvent) {
    e.api.sizeColumnsToFit();
    setGridApi(e.api);
  }

  function handleSelectionChanged(e: SelectionChangedEvent) {
    const selectedRows = e.api.getSelectedRows();

    if (selectedRows) {
      setSelectedRecord(selectedRows[0]);
    } else {
      setSelectedRecord(undefined);
    }
  }

  function handleSearchTextBoxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <Composition areas={areas} gap="1rem">
          {({ Search, Grid }) => (
            <>
              <Search>
                <InputGroup type="search" leftIcon="search" placeholder="Search" style={{ width: "50ch" }} onChange={handleSearchTextBoxChange} />
              </Search>
              <Grid>
                <div className="ag-theme-alpine" style={{ height: "600px" }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={rowData}
                    defaultColDef={{ sortable: true, resizable: true }}
                    suppressCellSelection={true}
                    immutableData={true}
                    getRowNodeId={(data) => data.id}
                    rowSelection="single"
                    onGridReady={handleGridReady}
                    onSelectionChanged={handleSelectionChanged}
                  />
                </div>
              </Grid>
            </>
          )}
        </Composition>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button intent={Intent.PRIMARY} disabled={!selectedRecord} onClick={() => onSelect(selectedRecord!)}>
            Select
          </Button>
        </div>
      </div>
    </>
  );
}
