import { IListing } from "../../models/IListing";

export function filterSections(listings: IListing[], filter: string): IListing[] {
  if (!filter || filter.length === 0) {
    return listings;
  }

  filter = sanitizeFilter(filter);
  let filterCopy = filter.slice();

  const startQuote = /"\b/;
  const endQuote = /\b"/;
  const startPos = filterCopy.search(startQuote);
  const endPos = filterCopy.search(endQuote);

  filterCopy = garbageQuoteRemover(filterCopy, startPos, endPos);

  const allListings: IListing[] = [];
  let parts: string | any[] = [];
  while (filterCopy !== "") {
    if (startQuote.test(filterCopy) && endQuote.test(filterCopy)) {
      let quotedString = filterCopy.substring(startPos, endPos + 1);
      parts.push(quotedString);
      filterCopy = filterCopy.replace(quotedString, "").trim();
    } else {
      parts = parts.concat(filterCopy.split(/\s+/));
      filterCopy = "";
    }
  }
  listings.forEach((l) => {
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (part.indexOf("-") === -1) {
        const partNumber = parseInt(part);

        if (startQuote.test(part) && endQuote.test(part)) {
          if (part.substring(1, part.length - 1).toLowerCase() === l.section.toLowerCase()) {
            allListings.push(l);
            return;
          }
        }

        if (l.section.split(/\s+/).some((s) => s.localeCompare(part, undefined, { sensitivity: "base" }) === 0 && isNaN(partNumber) === isNaN(parseInt(s)))) {
          allListings.push(l);
          return;
        } else if (l.section === part) {
          allListings.push(l);
          return;
        } else if (partNumber === parseInt(l.section)) {
          allListings.push(l);
          return;
        } else if (containsSectionNumber(l.section, partNumber)) {
          allListings.push(l);
          return;
        } else if (containsSectionString(l.section, part)) {
          allListings.push(l);
          return;
        }
      } else {
        const range = part.split("-");

        if (range.length !== 2) {
          break;
        }

        const min = range[0]; 
        const max = range[1]; 

        const minNumber = parseInt(min);
        const maxNumber = parseInt(max);

        if (isNaN(minNumber) !== isNaN(maxNumber)) {
          break;
        }

        if (isNaN(minNumber)) {
          const sectionArr = l.section.split(/\s+/);
          const itemIndex = sectionArr.findIndex((s) => s.length === min.length);
          if (itemIndex !== -1) {
            if (sectionArr[itemIndex] >= min && sectionArr[itemIndex] <= max && isNaN(parseInt(sectionArr[itemIndex])) === isNaN(minNumber)) {
              allListings.push(l);
              return;
            } 
            else if (containsSectionString(l.section, min)) {
              allListings.push(l);
              return;
            }
          }
        } else {
          if (l.section.split(/\s+/).some((s) => parseInt(s) >= minNumber && parseInt(s) <= maxNumber)) {
            allListings.push(l);
            return;
          }
          else if (containsSectionString(l.section, min)) {
            allListings.push(l);
            return;
          }
          else {
            let rangeNum = minNumber;
            while (rangeNum <= maxNumber) {
              if (containsSectionNumber(l.section, rangeNum)) {
                allListings.push(l);
                return;
              }
              rangeNum++;
            }
          }
        }
      }
    }
  });

  return allListings;
}

export function filterRows(listings: IListing[], filter: string): IListing[] {
  if (!filter || filter.length === 0) {
    return listings;
  }

  filter = sanitizeFilter(filter);

  const allListings: IListing[] = [];

  const parts = filter.split(/\s+/);

  listings.forEach((l) => {
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];

      if (part.indexOf("-") === -1) {
        if (l.row === part) {
          allListings.push(l);
          return;
        }
      } else {
        const range = part.split("-");

        if (range.length !== 2) {
          break;
        }

        const min = range[0];
        const max = range[1];

        const minNumber = parseInt(min);
        const maxNumber = parseInt(max);

        if (isNaN(minNumber) !== isNaN(maxNumber)) {
          break;
        }

        if (isNaN(minNumber)) {
          if (l.row >= range[0] && l.row <= range[1] && l.row.length === range[1].length) {
            allListings.push(l);
            return;
          }
        } else {
          if (parseInt(l.row) >= minNumber && parseInt(l.row) <= maxNumber) {
            allListings.push(l);
            return;
          }
        }
      }
    }
  });

  return allListings;
}

function sanitizeFilter(filter: string) {
  return filter.replace(",", " ");
}

function garbageQuoteRemover(filterCopy: string, startPos: number, endPos: number) {
  if (startPos > 0) {
    if (filterCopy.charAt(startPos - 1) !== " ") {
      filterCopy = filterCopy.slice(0, startPos) + filterCopy.slice(startPos + 1);
      startPos = filterCopy.search(/"\b/);
      endPos = filterCopy.search(/\b"/);
      filterCopy = garbageQuoteRemover(filterCopy, startPos, endPos);
      return filterCopy;
    } else {
      return filterCopy;
    }
  } else {
    return filterCopy;
  }
}

function containsSectionNumber(sectionName: string, partNumber: number) {
  const sectionNumber = sectionName.match(/\d+/g);
  if (sectionNumber !== null) {
    const sectionNumberInt = parseInt(sectionNumber[0]);
    if (sectionNumberInt === partNumber) {
      return true;
    }
  }
  return false;
}

function containsSectionString(sectionName: string, part: string) {
  if (!isNaN(parseInt(part))) {
    return false;
  }
  if (part.length < 2) {
    return false
  }
  if (sectionName.toLowerCase().indexOf(part.toLowerCase()) !== -1) {
    return true;
  }
  return false;
}
