import { Navbar, Classes, Icon } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import React from "react";
import { longEventDateTimeFormatter } from "../../utilities/Formatters";

const eventAreas = `
name name
date venue
`;

export interface ISelectedEventTitleProps {
  eventName: string;
  eventDate: Date;
  venueName: string;
  city: string | undefined;
  region: string | undefined;
}

export function SelectedEventTitle({ eventName, eventDate, venueName, city, region }: ISelectedEventTitleProps) {
  return (
    <>
      <Navbar.Divider />
      <Composition areas={eventAreas} gap="4px" paddingLeft="4px" templateCols="auto 1fr">
        {({ Name, Date, Venue }) => (
          <>
            <Name>
              <strong>{eventName}</strong>
            </Name>
            <Date>
              <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                <Icon icon="calendar" style={{ paddingRight: "4px" }} />
                {longEventDateTimeFormatter(eventDate)}
              </div>
            </Date>
            <Venue>
              <div className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}>
                <Icon icon="map-marker" style={{ paddingRight: "4px" }} />
                {`${venueName} - ${city ?? ""}, ${region ?? ""}`}
              </div>
            </Venue>
          </>
        )}
      </Composition>
    </>
  );
}
