import { FormGroup, TextArea, Intent, Button, Dialog, Classes } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { FormikErrors, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPointOfSaleIntegration } from "../../../../slices/IntegrationsSlice";
import { PointOfSaleLogo } from "../../../shared/PointOfSaleLogo";
import JSON5 from "json5";

interface SkyBoxFormValues {
  tokenJson: string;
}

function validateSkyBox(values: SkyBoxFormValues) {
  const errors: FormikErrors<SkyBoxFormValues> = {};

  if (!values.tokenJson) {
    errors.tokenJson = "Required";
  } else {
    try {
      const obj = JSON5.parse(values.tokenJson);

      if (!obj || (obj.token as string).length !== 36) {
        errors.tokenJson = "Invalid token";
      }
    } catch {
      errors.tokenJson = "Invalid token";
    }
  }

  return errors;
}

export function SkyBoxPointOfSalePanel() {
  const dispatch = useDispatch();

  const [isHelpOpen, setHelpOpen] = useState(false);

  function onSubmit(params: SkyBoxFormValues) {
    dispatch(addPointOfSaleIntegration(1, params.tokenJson, "{}"));
  }

  return (
    <Composition areas="logo config" gap="1rem" alignItems="center" justifyContent="center" templateCols="auto 1fr" height="100%" width="100%" padding="1rem">
      {(Areas) => (
        <>
          <Areas.Logo>
            <PointOfSaleLogo name="SkyBox" />
          </Areas.Logo>
          <Areas.Config>
            <Formik initialValues={{ tokenJson: "" }} onSubmit={onSubmit} validate={validateSkyBox}>
              {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form>
                  <FormGroup
                    label="SkyBox Token"
                    helperText={errors.tokenJson ?? "Paste your token info into the box and click the Connect button"}
                    labelInfo="(required)"
                    labelFor="token-input"
                  >
                    <TextArea
                      id="token-input"
                      intent={errors.tokenJson ? Intent.WARNING : Intent.NONE}
                      fill={true}
                      style={{ height: "130px" }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tokenJson}
                      name="tokenJson"
                    />
                  </FormGroup>

                  <Button style={{ marginRight: "1rem" }} disabled={isSubmitting} onClick={() => setHelpOpen(true)}>
                    Token Info?
                  </Button>
                  <Button
                    intent={Intent.PRIMARY}
                    disabled={isSubmitting || !!errors.tokenJson || !!(values.tokenJson.length < 1)}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Connect
                  </Button>
                </form>
              )}
            </Formik>
            <Dialog icon="help" title="How to Obtain a SkyBox API Token" onClose={() => setHelpOpen(false)} isOpen={isHelpOpen}>
              <div className={Classes.DIALOG_BODY}>
                <p>This is where I will put the explanation to users on how they generate an API token from their SkyBox POS</p>
                <p>For test purposes use the JSON below.</p>
                <code>
                  {"{"}
                  "token":"4d0890f2-685e-4ebc-879f-1015a445f9be","accountId":2642,"defaultVendorId":818343
                  {"}"}
                </code>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button onClick={() => setHelpOpen(false)}>Close</Button>
                </div>
              </div>
            </Dialog>
          </Areas.Config>
        </>
      )}
    </Composition>
  );
}
