import { Intent, Button, NonIdealState, IPanelProps } from "@blueprintjs/core";
import React from "react";
import { SelectPointOfSalePanel } from "./SelectPointOfSalePanel";

export function NoPointOfSalePanel(props: IPanelProps) {
  function openSelectPointOfSalePanel() {
    props.openPanel({
      component: SelectPointOfSalePanel,
      title: "Select Point of Sale",
    });
  }

  const connectButton = (
    <Button icon="database" intent={Intent.PRIMARY} onClick={() => openSelectPointOfSalePanel()}>
      Connect
    </Button>
  );

  return <NonIdealState icon="desktop" title="No Point of Sale Connected" description="Connect your point of sale to begin using Nexus" action={connectButton} />;
}
