import React, { useEffect, useState } from "react";
import { Card, Elevation, H3, Classes, HTMLSelect, Divider, Callout, Tag, Button, Intent } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { MenuPickerButton, IMenuPickerItem } from "../shared/MenuPickerButton";
import { SoftLabel } from "../shared/Typography";
import { currencyFormatter } from "../../utilities/Formatters";
import { IDeliveryMethod } from "../../models/IDeliveryMethod";
import { IPurchaseResponse } from "../../models/IPurchaseResponse";
import { useMutation, useQuery } from "react-query";
import { getDefaultCustomer, getEventListingDeliveryMethods, postPurchaseTickets } from "../../nexusApi";
import { IListing } from "../../models/IListing";
import { IPurchaseTicketsRequest } from "../../models/IPurchaseTicketRequest";
import { v4 as uuidv4 } from "uuid";

const cardAreas = `
location attributes
separator attributes
price Buttons
`;

const locationAreas = `
section section
row quantity
`;

const priceAreas = `
priceLabel priceValue
feesLabel feesValue
deliveryLabel deliveryValue
separator separator
totalLabel totalValue
`;

const attributeAreas = `
marketplace
notes
delivery
`;

const deliveryItems = (deliveryMethods: IDeliveryMethod[] | undefined): IMenuPickerItem[] => {
  if (!deliveryMethods) {
    return [];
  }

  return deliveryMethods.map((dm) => {
    return {
      id: dm.id,
      text: `${dm.description} (${currencyFormatter(dm.price)})`,
      icon: dm.id === 1 ? "mobile-phone" : "document",
    };
  });
};

export interface IPurchaseEventListingCardState {
  deliveryMethods: IDeliveryMethod[] | undefined;
  isPurchasing: boolean;
  purchase: IPurchaseResponse | undefined;
}

export interface IPurchaseEventListingCardProps extends IListing {
  eventId: number;

  onPurchase(purchase: IPurchaseResponse): void;

  onCancel(): void;
}

export function PurchaseEventListingCard({
  eventId,
  section,
  row,
  buyQuantityOptions,
  unitPrice,
  unitFees,
  notes,
  exchange,
  id,
  exchangeEventId,
  onPurchase,
  onCancel,
}: IPurchaseEventListingCardProps) {
  const listingId = parseInt(id ?? "");

  const { isLoading, data: deliveryMethods } = useQuery(
    ["deliveryMethods", eventId, exchange!.id, listingId],
    () => getEventListingDeliveryMethods(eventId, exchange!.id, listingId).then((res) => res.data.data),
    { staleTime: 5 * 60 * 1000 }
  );

  const { data: defaultCustomer } = useQuery("defaultCustomer", () => getDefaultCustomer().then((res) => res.data.data), { staleTime: 5 * 60 * 1000 });

  const [selectedQuantity, setSelectedQuantity] = useState(0);

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<IDeliveryMethod>();

  useEffect(() => {
    if (buyQuantityOptions) {
      setSelectedQuantity(buyQuantityOptions.some((q) => q === 2) ? 2 : buyQuantityOptions[0]);
    }
  }, [buyQuantityOptions]);

  const createPurchase = ({ exchangeId, purchase }: IPurchaseTicketsRequest) => {
    return postPurchaseTickets({ exchangeId, purchase });
  };

  const [makePurchase, { isLoading: isPurchasing }] = useMutation(createPurchase);

  if (!buyQuantityOptions || !eventId) return null;

  function handleQtySelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedQuantity(parseInt(e.target.value));
  }

  async function handlePurchaseClick() {
    if (
      unitPrice === undefined ||
      unitFees === undefined ||
      selectedDeliveryMethod === undefined ||
      exchange === undefined ||
      exchangeEventId === undefined ||
      defaultCustomer === undefined
    )
      return;

    const purchase = {
      guid: uuidv4(),
      eventId: exchangeEventId,
      listingId: listingId.toString(),
      quantity: selectedQuantity,
      unitPrice,
      unitFees,
      deliveryMethod: selectedDeliveryMethod,
      customer: {
        firstName: defaultCustomer.firstName,
        lastName: defaultCustomer.lastName,
        phone: defaultCustomer.phone,
        email: defaultCustomer.email,
      },
    };

    const response = await makePurchase({ exchangeId: exchange!.id, purchase });

    if (response) {
      onPurchase(response.data.data);
    }
  }

  function handleDeliveryMethodChange(item: IMenuPickerItem | undefined) {
    const deliveryMethod = deliveryMethods?.find((dm) => dm.id === item?.id);
    setSelectedDeliveryMethod(deliveryMethod);
  }

  return (
    <Card elevation={Elevation.TWO} style={{ width: "800px" }}>
      <Composition areas={cardAreas} gap="1rem" padding="2rem" templateCols="50% 50%">
        {({ Location, Attributes, Separator, Price, Buttons }) => (
          <>
            <Location>
              <Composition areas={locationAreas} gap="1rem" padding="1rem" templateCols="1fr auto" height="100%">
                {({ Section, Row, Quantity }) => (
                  <>
                    <Section>
                      <SoftLabel>Section</SoftLabel>
                      <H3>{section}</H3>
                    </Section>
                    <Row>
                      <SoftLabel>Row</SoftLabel>
                      <H3>{row}</H3>
                    </Row>
                    <Quantity>
                      <SoftLabel>Quantity</SoftLabel>
                      <HTMLSelect options={buyQuantityOptions} value={selectedQuantity} large={false} fill={true} onChange={handleQtySelectChange} />
                    </Quantity>
                  </>
                )}
              </Composition>
            </Location>
            <Attributes>
              <Composition areas={attributeAreas} gap="1rem" padding="1rem" height="100%">
                {({ Marketplace, Notes, Delivery }) => (
                  <>
                    <Marketplace>
                      <Tag icon="shop" large={true} minimal={true}>
                        {exchange?.name}
                      </Tag>
                    </Marketplace>
                    <Notes>
                      {(notes?.length ?? 0) > 0 && (
                        <Callout icon="annotation">
                          <span className={Classes.TEXT_MUTED}>{notes}</span>
                        </Callout>
                      )}
                    </Notes>
                    <Delivery align="end">
                      <SoftLabel>Delivery</SoftLabel>
                      {deliveryMethods && deliveryMethods.length > 0 && (
                        <MenuPickerButton
                          items={deliveryItems(deliveryMethods)}
                          defaultItemId={deliveryItems(deliveryMethods)[0].id}
                          onSelectedItemChange={handleDeliveryMethodChange}
                        />
                      )}
                      {(!deliveryMethods || deliveryMethods.length === 0) && <MenuPickerButton defaultText="No Delivery Available" loading={isLoading} items={[]} />}
                    </Delivery>
                  </>
                )}
              </Composition>
            </Attributes>
            <Separator>
              <Divider />
            </Separator>
            <Price alignContent="end">
              <Composition areas={priceAreas} gap="0.5rem" padding="1rem">
                {({ PriceLabel, PriceValue, FeesLabel, FeesValue, DeliveryLabel, DeliveryValue, Separator, TotalLabel, TotalValue }) => (
                  <>
                    <PriceLabel>
                      <SoftLabel>Price</SoftLabel>
                    </PriceLabel>
                    <PriceValue justify="end">
                      <SoftLabel>
                        {currencyFormatter(unitPrice)} x{selectedQuantity}
                      </SoftLabel>
                    </PriceValue>
                    <FeesLabel>
                      <SoftLabel>Fees</SoftLabel>
                    </FeesLabel>
                    <FeesValue justify="end">
                      <SoftLabel>
                        {currencyFormatter(unitFees)} x{selectedQuantity}
                      </SoftLabel>
                    </FeesValue>
                    <DeliveryLabel>
                      <SoftLabel>Delivery</SoftLabel>
                    </DeliveryLabel>
                    <DeliveryValue justify="end">
                      <SoftLabel>{currencyFormatter(selectedDeliveryMethod?.price)} x1</SoftLabel>
                    </DeliveryValue>
                    <Separator>
                      <Divider />
                    </Separator>
                    <TotalLabel>
                      <H3>Total</H3>
                    </TotalLabel>
                    <TotalValue justify="end">
                      <H3>{currencyFormatter((unitPrice! + unitFees!) * selectedQuantity + (selectedDeliveryMethod?.price ?? 0))}</H3>
                    </TotalValue>
                  </>
                )}
              </Composition>
            </Price>
            <Buttons justify="center" align="end" padding="1rem">
              <Composition areas="cancel purchase" gap="1rem">
                {({ Cancel, Purchase }) => (
                  <>
                    <Cancel>
                      <Button large={true} disabled={isPurchasing} onClick={() => onCancel()}>
                        Cancel
                      </Button>
                    </Cancel>
                    <Purchase>
                      <Button
                        rightIcon="arrow-right"
                        large={true}
                        intent={Intent.PRIMARY}
                        disabled={
                          selectedQuantity <= 0 ||
                          !selectedDeliveryMethod ||
                          !deliveryMethods ||
                          deliveryMethods.length === 0 ||
                          unitFees === undefined ||
                          !unitPrice ||
                          !exchange ||
                          !exchangeEventId
                        }
                        loading={isPurchasing}
                        onClick={() => handlePurchaseClick()}
                      >
                        Purchase
                      </Button>
                    </Purchase>
                  </>
                )}
              </Composition>
            </Buttons>
          </>
        )}
      </Composition>
    </Card>
  );
}
