import React from "react";
import { LineChart, Line, CartesianGrid, Tooltip, Legend, XAxis, YAxis } from 'recharts';
import { Colors } from "@blueprintjs/core";
import { getMonitor } from "../../nexusApi";
import { useQuery } from "react-query";
import { IMonitorSnapshot } from "../../models/IMonitorSnapshot";
import {compareAsc, format} from "date-fns";

export function MonitorTrendChart(props: { monitorID: string }) {

  const monitorID = props.monitorID;

  const { data: monitorData } = useQuery(["monitorData", props.monitorID], () => getMonitor(monitorID), {
    refetchOnWindowFocus: false,
  });

  const cleanedSnapshots: IMonitorSnapshot[] = [];

  monitorData?.data.data.snapshots.forEach((snapshot: IMonitorSnapshot) => {
    snapshot.createdAt = new Date(snapshot.createdAt);

    if (!cleanedSnapshots.find(cs => sameDay(cs.createdAt, snapshot.createdAt))) {
      cleanedSnapshots.push(snapshot);
    }
  });

  cleanedSnapshots.sort((a, b) => compareAsc(a.createdAt, b.createdAt));

  const filteredDataSnapshots = cleanedSnapshots.map((snapshot) => {
    return {
      createdAt: format(snapshot.createdAt, "MM/dd"),
      numTickets: snapshot.numTickets,
      medPrice: snapshot.medPrice,
      medPriceString: "$" + snapshot.medPrice.toString()
    }
  })

  function sameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  }

  const PriceFormatter = (price: Number) => {
    return '$' + price.toString();
  }

  return (
    <div>
      <div style={{ color: Colors.BLACK, background: "#F8F8F8", borderStyle: "solid", borderWidth: "1px", borderColor: Colors.GRAY4, textAlign: "center", height: "50px", fontSize: "13px", lineHeight: "normal", fontWeight: 700, paddingTop: "15px", borderLeft: "none", borderRight: "none" }}>Monitor Trends</div>
      <LineChart width={500} height={300} data={filteredDataSnapshots} style={{ justifyContent: "center", marginTop: "20px" }}>
        <Line name="Price" yAxisId="left" type="monotone" dataKey="medPrice" stroke="#8884d8" />
        <Line name="Tickets" yAxisId="right" type="monotone" dataKey="numTickets" stroke="#892934" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <Tooltip />
        <Legend />
        <XAxis dataKey="createdAt" />
        <YAxis yAxisId="left" tickFormatter={PriceFormatter} />
        <YAxis yAxisId="right" orientation="right" />
      </LineChart>
    </div>
  );
}

