import React, { useState } from "react";
import { ControlGroup, Button, NumberRange, RangeSlider } from "@blueprintjs/core";
import accounting from "accounting";
import { Popover2, Classes } from "@blueprintjs/popover2";

export interface IPriceRangeProps {
  initialRange?: NumberRange;
  selectedRange?: NumberRange; // For controlled mode
  onChange?(newValues: NumberRange): void;
}

function getButtonLabelText(minAmt: number, maxAmt: number) {
  if (minAmt === 0 && maxAmt === 1000) {
    return "$0 - $999+";
  }

  return `${accounting.formatMoney(minAmt, {
    precision: 0,
  })} - ${accounting.formatMoney(maxAmt, { precision: 0 })}`;
}

export function PriceRangeFilter({ initialRange, selectedRange, onChange }: IPriceRangeProps) {
  const rangeMin = initialRange ? initialRange[0] : 0;
  const rangeMax = initialRange ? initialRange[1] : 1000;

  const [internalRange, setInternalRange] = useState<NumberRange>([rangeMin, rangeMax]);
  const [isOpen, setIsOpen] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const range = isChanging ? internalRange : selectedRange ?? internalRange;

  const isClearButtonVisible = range[0] !== rangeMin || range[1] !== rangeMax;

  function resetState() {
    setIsChanging(false);
    setInternalRange([rangeMin, rangeMax]);

    if (onChange) {
      onChange([rangeMin, rangeMax]);
    }
  }

  function setRange(newRange: NumberRange) {
    setIsChanging(true);
    setInternalRange(newRange);
  }

  function handleOnRelease() {
    setIsChanging(false);

    if (onChange) {
      onChange(range);
    }
  }

  function renderSliderLabel(val: number) {
    return accounting.formatMoney(val ?? 0, { precision: 0 });
  }

  const sliderMin = 0;
  const sliderMax = 1000 > rangeMax ? 1000 : rangeMax;

  return (
    <Popover2
      content={
        <div style={{ width: "300px", padding: "1rem" }}>
          <RangeSlider min={sliderMin} max={sliderMax} labelStepSize={sliderMax} labelRenderer={renderSliderLabel} value={range} onChange={setRange} onRelease={handleOnRelease} />
        </div>
      }
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ControlGroup>
        <Button rightIcon="caret-down" text={getButtonLabelText(range[0], range[1])} onClick={() => setIsOpen(!isOpen)} />
        {isClearButtonVisible && <Button icon="cross" onClick={() => resetState()} />}
      </ControlGroup>
    </Popover2>
  );
}
