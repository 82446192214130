import React, { useState } from "react";
import { Elevation, Card, H4, Classes, Divider, Button, Intent, Colors } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { PurchaseCustomerCard } from "./PurchaseCustomerCard";
import { PurchaseEventCard } from "./PurchaseEventCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPurchaseResponse } from "../../models/IPurchaseResponse";
import { currencyFormatter } from "../../utilities/Formatters";
import { IEvent } from "../../models/IEvent";
import { IPosCustomer } from "../../models/IPosCustomer";
import { IPosPurchase } from "../../models/IPosPurchase";
import { postPosPurchase } from "../../nexusApi";
import { useMutation } from "react-query";
import { AppToaster } from "../AppToaster";
import { RootState } from "../../rootReducer";
import { useSelector } from "react-redux";

export interface IPurchaseFinalizeCardProps extends IPurchaseResponse {
  event: IEvent;

  onClose(): void;
}

const areas = `
    summary summary
    divider divider
    customer event
    buttons buttons
`;

export function PurchaseFinalizeCard({ listing, seller, total, event, id, deliveryMethod, exchangeOrderId, onClose }: IPurchaseFinalizeCardProps) {
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>();
  const [selectedEventId, setSelectedEventId] = useState<string>();

  const { name: posName } = useSelector((state: RootState) => state.integrations.pointOfSale);

  const createPurchase = (purchase: IPosPurchase) => {
    return postPosPurchase(purchase);
  };

  const [makePurchase, { isLoading: isPurchasing }] = useMutation(createPurchase);

  function handleCustomerSelect(customer: IPosCustomer) {
    setSelectedCustomerId(customer.id);
  }

  function handleEventSelect(event: IEvent) {
    setSelectedEventId(event.id.toString());
  }

  async function handlePurchaseClick() {
    if (!selectedCustomerId || !selectedEventId) {
      return;
    }

    const purchase = {
      id,
      eventId: selectedEventId,
      customerId: selectedCustomerId,
      referenceNumber: exchangeOrderId,
      totalCost: {
        value: total,
        currencyCode: "USD",
      },
      listing: {
        section: listing.section,
        row: listing.row,
        seats: listing.seats,
        notes: listing.notes,
        deliveryMethod: deliveryMethod.id,
      },
    };

    const response = await makePurchase(purchase);

    if (response) {
      AppToaster.show({
        message: `Purchase ${response.data.data.id} created`,
        intent: Intent.SUCCESS,
      });
    }

    onClose();
  }

  return (
    <Card elevation={Elevation.TWO}>
      <Composition areas={areas} gap="1rem" padding="1rem">
        {(Areas) => (
          <>
            <Areas.Summary>
              <Composition areas="icon section row quantity total" templateCols="1fr repeat(5, auto)" gap="2rem">
                {(Summary) => (
                  <>
                    <Summary.Icon>
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon icon="shopping-cart" color={Colors.LIGHT_GRAY1} size="2x" />
                        <FontAwesomeIcon icon="check" color={Colors.GREEN2} size="2x" />
                      </span>
                    </Summary.Icon>
                    <Summary.Section>
                      <H4 className={Classes.TEXT_MUTED} style={{ display: "inline", paddingRight: "1rem" }}>
                        Section
                      </H4>
                      <H4 style={{ display: "inline" }}>{listing.section}</H4>
                    </Summary.Section>
                    <Summary.Row>
                      <H4 className={Classes.TEXT_MUTED} style={{ display: "inline", paddingRight: "1rem" }}>
                        Row
                      </H4>
                      <H4 style={{ display: "inline" }}>{listing.row}</H4>
                    </Summary.Row>
                    <Summary.Quantity>
                      <H4 className={Classes.TEXT_MUTED} style={{ display: "inline", paddingRight: "1rem" }}>
                        Qty
                      </H4>
                      <H4 style={{ display: "inline" }}>{listing.quantity}</H4>
                    </Summary.Quantity>
                    <Summary.Total>
                      <H4 className={Classes.TEXT_MUTED} style={{ display: "inline", paddingRight: "1rem" }}>
                        Total
                      </H4>
                      <H4 style={{ display: "inline" }}>{currencyFormatter(total)}</H4>
                    </Summary.Total>
                  </>
                )}
              </Composition>
            </Areas.Summary>
            <Areas.Divider>
              <Divider />
            </Areas.Divider>
            <Areas.Customer justify="center">
              <PurchaseCustomerCard {...seller} onCustomerSelect={handleCustomerSelect} />
            </Areas.Customer>
            <Areas.Event justify="center">
              <PurchaseEventCard {...event} onEventSelect={handleEventSelect} />
            </Areas.Event>
            <Areas.Buttons justify="end">
              <Composition areas="cancel add" gap="1rem">
                {({ Cancel, Add }) => (
                  <>
                    <Cancel>
                      <Button large={true} disabled={isPurchasing} onClick={onClose}>
                        Close
                      </Button>
                    </Cancel>
                    <Add>
                      <Button
                        text={`Add to ${posName}`}
                        large={true}
                        intent={Intent.PRIMARY}
                        disabled={!selectedCustomerId || !selectedEventId}
                        loading={isPurchasing}
                        onClick={handlePurchaseClick}
                      />
                    </Add>
                  </>
                )}
              </Composition>
            </Areas.Buttons>
          </>
        )}
      </Composition>
    </Card>
  );
}
