import { IExchangeId } from "../../models/IExchangeId";
import { AnchorButton, Button, ButtonGroup, IconName, InputGroup, Menu, MenuItem } from "@blueprintjs/core";
import React, { useState } from "react";
import { Classes, Popover2 } from "@blueprintjs/popover2";
import { useMutation } from "react-query";
import { postEventMapping } from "../../nexusApi";

export function EventLinkButtonGroup(props: { exchangeIds: IExchangeId[] | undefined; onChange: () => void }) {
  const vsEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 1)?.id;
  const shEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 2)?.id;
  const tmEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 3)?.id;
  const teEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 4)?.id;
  const tnEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 5)?.id;
  const llEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 6)?.id;
  const tpEventId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === 7)?.id;

  const [addMapping, { isLoading: isAddingMapping }] = useMutation(postEventMapping);

  function handleIdChange(exchange: number, eventIdOverride: string) {
    if (eventIdOverride.length === 0 || !vsEventId) {
      return;
    }

    const existingId = props.exchangeIds?.find((exchangeId) => exchangeId.exchange === exchange)?.id;

    if (existingId?.toString() === eventIdOverride) {
      return;
    }

    addMapping({
      eventId: parseInt(vsEventId.toString()),
      exchangeId: {
        exchange,
        id: eventIdOverride,
      },
    }).then(() => {
      props.onChange();
    });
  }

  return (
    <ButtonGroup>
      <AnchorButton href={`https://vividseats.com/production/${vsEventId}`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!vsEventId}>
        VS
      </AnchorButton>
      <AnchorButton href={`https://stubhub.com/event/${shEventId}`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!shEventId}>
        SH
      </AnchorButton>
      <AnchorButton href={`https://ticketmaster.com/event/${tmEventId}`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!tmEventId}>
        TM
      </AnchorButton>
      <AnchorButton href={`https://core.ticketevolution.com/tickets/events/all/${teEventId}/all`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!teEventId}>
        TE
      </AnchorButton>
      <AnchorButton href={`https://ticketnetwork.com/tickets/${tnEventId}`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!tnEventId}>
        TN
      </AnchorButton>
      <AnchorButton disabled={!llEventId}>LL</AnchorButton>
      <AnchorButton href={`https://tickpick.com/buy-event/${tpEventId}`} target={"_blank"} referrerPolicy={"no-referrer"} disabled={!tpEventId}>
        TP
      </AnchorButton>
      <Popover2
        content={
          <Menu>
            <PopoverMenuItemInput menuItemText={"Vivid Seats"} value={vsEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(1, e)} />
            <PopoverMenuItemInput menuItemText={"StubHub"} value={shEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(2, e)} />
            <PopoverMenuItemInput menuItemText={"Ticketmaster"} value={tmEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(3, e)} />
            <PopoverMenuItemInput menuItemText={"Ticket Evolution"} value={teEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(4, e)} />
            <PopoverMenuItemInput menuItemText={"TicketNetwork"} value={tnEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(5, e)} />
            <PopoverMenuItemInput menuItemText={"Logitix Live"} value={llEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(6, e)} />
            <PopoverMenuItemInput menuItemText={"TickPick"} value={tpEventId?.toString() ?? ""} isLoading={isAddingMapping} onChange={(e) => handleIdChange(7, e)} />
          </Menu>
        }
      >
        <Button icon={"caret-down"}></Button>
      </Popover2>
    </ButtonGroup>
  );
}

export interface IPopoverMenuItemInputProps {
  menuItemText: string;
  icon?: IconName;
  inputPlaceholder?: string;
  value: string;
  isLoading: boolean;

  onChange?(newValue: string): void;
}

export function PopoverMenuItemInput({ menuItemText, inputPlaceholder, value, onChange, isLoading }: IPopoverMenuItemInputProps) {
  const [inputValue, setInputValue] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const onSubmit = (oldValue?: string, newValue?: string, onChange?: Function) => {
    if (oldValue === newValue) {
      return;
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsDirty(true);
  };

  return (
    <Popover2
      interactionKind={"hover"}
      popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
      fill={true}
      hoverOpenDelay={0}
      hoverCloseDelay={100}
      content={
        <InputGroup
          type="text"
          placeholder={inputPlaceholder}
          disabled={isLoading}
          defaultValue={value}
          autoFocus={true}
          onChange={handleChange}
          rightElement={
            <Button
              minimal={true}
              icon={isDirty ? "tick" : "blank"}
              disabled={!isDirty || isLoading}
              loading={isLoading}
              onClick={() => {
                onSubmit(value, inputValue, onChange);
              }}
            />
          }
        />
      }
    >
      <MenuItem text={menuItemText} />
    </Popover2>
  );
}
