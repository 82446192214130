import { FormGroup, Intent, Button, InputGroup, Dialog, Classes, H1 } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import { Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPointOfSaleIntegration } from "../../../../slices/IntegrationsSlice";
import { PointOfSaleLogo } from "../../../shared/PointOfSaleLogo";

interface PosNextFormValues {
  brokerId: string;
}

function validatePosNext(values: PosNextFormValues) {
  const errors: FormikErrors<PosNextFormValues> = {};

  if (!values.brokerId || values.brokerId.length === 0) {
    errors.brokerId = "Required";
  } else {
    const isnum = /^\d+$/.test(values.brokerId);

    if (!isnum) {
      errors.brokerId = "Broker ID must be whole number";
    }
  }

  return errors;
}

export function PosNextPointOfSalePanel() {
  const dispatch = useDispatch();

  const [isHelpOpen, setHelpOpen] = useState(false);

  function onSubmit(values: PosNextFormValues) {
    dispatch(addPointOfSaleIntegration(2, JSON.stringify({ "broker-id": values.brokerId }), "{}"));
  }

  return (
    <Composition areas="logo config" gap="1rem" alignItems="center" justifyContent="center" templateCols="auto 1fr" height="100%" width="100%" padding="1rem">
      {(Areas) => (
        <>
          <Areas.Logo>
            <PointOfSaleLogo name="PosNext" />
          </Areas.Logo>
          <Areas.Config>
            <Formik initialValues={{ brokerId: "" }} onSubmit={onSubmit} validate={validatePosNext}>
              {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form>
                  <FormGroup
                    label="TicketNetwork Broker ID"
                    helperText={errors.brokerId ?? "Enter your Broker ID into the box and click the Connect button"}
                    labelInfo="(required)"
                    labelFor="brokerId-input"
                  >
                    <InputGroup // HACKHACK NumericInput blows up with Formik, so I'm using a regular text input
                      id="brokerId-input"
                      intent={errors.brokerId ? Intent.DANGER : Intent.NONE}
                      placeholder="1234"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="brokerId"
                      value={values.brokerId}
                      style={{ width: "10ch" }}
                    />
                  </FormGroup>

                  <Button style={{ marginRight: "1rem" }} disabled={isSubmitting} onClick={() => setHelpOpen(true)}>
                    Broker ID?
                  </Button>
                  <Button
                    intent={Intent.PRIMARY}
                    disabled={isSubmitting || !!errors.brokerId || !values || !values.brokerId || values.brokerId.length === 0}
                    loading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Connect
                  </Button>
                </form>
              )}
            </Formik>
            <Dialog icon="help" title="How to Find Your Broker ID" onClose={() => setHelpOpen(false)} isOpen={isHelpOpen}>
              <div className={Classes.DIALOG_BODY}>
                <p>This is where I will put the explanation to users on how they can locate their TicketNetwork Broker ID.</p>
                <p>For test purposes use the sandbox Broker ID below.</p>
                <H1>9749</H1>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button onClick={() => setHelpOpen(false)}>Close</Button>
                </div>
              </div>
            </Dialog>
          </Areas.Config>
        </>
      )}
    </Composition>
  );
}
