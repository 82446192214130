import { Card, IPanelProps } from "@blueprintjs/core";
import { Composition } from "atomic-layout";
import React from "react";
import { PointOfSaleLogo } from "../../../shared/PointOfSaleLogo";
import { SkyBoxPointOfSalePanel } from "./SkyBoxPointOfSalePanel";
import { PosNextPointOfSalePanel } from "./PosNextPointOfSalePanel";
import styled from "styled-components";

const StyledPosCard = styled(Card)`
  height: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function SelectPointOfSalePanel(props: IPanelProps) {
  function openSkyBoxPointOfSalePanel() {
    props.openPanel({
      component: SkyBoxPointOfSalePanel,
      title: "SkyBox Configuration",
    });
  }

  function openPosNextPointOfSalePanel() {
    props.openPanel({
      component: PosNextPointOfSalePanel,
      title: "PosNext Configuration",
    });
  }

  return (
    <Composition areas="skybox posnext" gap="1rem" justifyItems="center" alignItems="center" height="100%">
      {(Areas) => (
        <>
          <Areas.Skybox>
            <StyledPosCard interactive={true} onClick={() => openSkyBoxPointOfSalePanel()}>
              <PointOfSaleLogo name="SkyBox" />
            </StyledPosCard>
          </Areas.Skybox>
          <Areas.Posnext>
            <StyledPosCard interactive={true} onClick={() => openPosNextPointOfSalePanel()}>
              <PointOfSaleLogo name="PosNext" />
            </StyledPosCard>
          </Areas.Posnext>
        </>
      )}
    </Composition>
  );
}
