import React, { useEffect, useState } from "react";
import { IconName, Button, Menu, ButtonGroup, Position, MenuItem } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";

export interface IMenuPickerItem {
  id: number;
  text: string;
  icon: IconName | undefined;
}

export interface IMenuPickerProps {
  defaultText?: string;
  defaultItemId?: number;
  items: IMenuPickerItem[];
  loading?: boolean;
  selectedItem?: IMenuPickerItem | undefined; // For controlled mode
  onSelectedItemChange?: IMenuPickerItemChangeCallback; // For controlled mode
}

export interface IMenuPickerItemChangeCallback {
  (item: IMenuPickerItem | undefined): void;
}

export function MenuPickerButton({ defaultText, defaultItemId, items, loading, selectedItem, onSelectedItemChange }: IMenuPickerProps) {
  if ((!defaultText && defaultItemId === undefined) || (defaultText && defaultItemId)) {
    throw new Error("Must provide either defaultText or defaultItemId");
  }

  const [internalSelectedItem, setInternalSelectedItem] = useState<IMenuPickerItem | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  const defaultItem = items.find((i) => i.id === defaultItemId);

  const [currentItem, setCurrentItem] = useState(selectedItem ?? internalSelectedItem);

  function setSelectedItem(item: IMenuPickerItem | undefined) {
    setCurrentItem(item);

    if (onSelectedItemChange) {
      onSelectedItemChange(item);
    } else {
      setInternalSelectedItem(item);
    }
  }

  useEffect(() => {
    if (defaultItem !== undefined && (currentItem === undefined || !items.some((i) => i.id === currentItem.id))) {
      setSelectedItem(defaultItem);
    } // eslint-disable-next-line
  }, [defaultItem, currentItem, items]);

  if (items.length === 0) {
    if (!defaultText) {
      return null;
    }

    return <Button icon="warning-sign" text={defaultText} rightIcon="caret-down" disabled={true} loading={loading} />;
  }

  function handleSelect(item: IMenuPickerItem | undefined) {
    setSelectedItem(item);
  }

  if (!defaultItem && !defaultText) {
    throw new Error("defaultItemId not found in items");
  }

  return (
    <Popover2
      content={
        <Menu>
          {items.map((item) => {
            return <MenuItem key={item.id} icon={item.icon} text={item.text} onClick={() => handleSelect(item)} active={currentItem?.id === item.id} />;
          })}
        </Menu>
      }
      position={Position.BOTTOM}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ButtonGroup>
        {currentItem ? (
          <Button icon={currentItem.icon} text={currentItem.text} rightIcon="caret-down" loading={loading} onClick={() => setIsOpen(!isOpen)} />
        ) : (
          <Button text={defaultText} rightIcon="caret-down" loading={loading} onClick={() => setIsOpen(!isOpen)} />
        )}
        {defaultText && currentItem ? <Button icon="cross" onClick={() => handleSelect(undefined)} /> : undefined}
      </ButtonGroup>
    </Popover2>
  );
}
