import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSubscription, fetchSubscriptionPlans } from "../../../../slices/SubscriptionsSlice";
import { RootState } from "../../../../rootReducer";
import { PayPalIntegrationCard } from "./PayPalIntegrationCard";

export function SubscriptionList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubscriptionPlans());
    dispatch(fetchSubscription());
  }, [dispatch]);

  const { plans, subscription } = useSelector((state: RootState) => state.subscription);

  if (plans === undefined || plans.length === 0) return null;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {plans.map((plan, i) => {
        return <PayPalIntegrationCard currentSubscriptionPlanId={subscription?.planId} {...plan} key={plan.id} />;
      })}
    </div>
  );
}
