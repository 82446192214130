import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../rootReducer";
import { WalletItem } from "./WalletItem";

export function Wallet() {
  const items = useSelector((state: RootState) => state.wallet);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div>
      {items
        .filter((i) => i.availableBalance !== -1)
        .map((item) => (
          <WalletItem {...item} key={item.exchange.id} />
        ))}
    </div>
  );
}

export default Wallet;
