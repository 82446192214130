import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { getSubscription, getSubscriptionPlans, postSubscription } from "../nexusApi";
import { ISubscription } from "../models/ISubscription";
import { ISubscriptionPlan } from "../models/ISubscriptionPlan";

export interface ISubscriptionState {
  subscription: ISubscription;
  isSubscriptionLoading: boolean;
  isSubscribed: boolean;
  plans: ISubscriptionPlan[];
}

const subscriptionsSlice = createSlice({
  name: "subscription",
  initialState: { isSubscribed: true } as ISubscriptionState,
  reducers: {
    setSubscriptionSuccess(state, action: PayloadAction<ISubscription>) {
      state.isSubscriptionLoading = false;
      state.subscription = action.payload;
      state.isSubscribed = action.payload !== undefined;
    },
    setSubscriptionFailure(state, action: PayloadAction<string>) {
      state.isSubscriptionLoading = false;
    },
    setSubscriptionLoading(state) {
      state.isSubscriptionLoading = true;
    },
    setSubscriptionPlansSuccess(state, action: PayloadAction<ISubscriptionPlan[]>) {
      state.plans = action.payload;
    },
  },
});

export const { setSubscriptionSuccess, setSubscriptionPlansSuccess, setSubscriptionLoading } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;

export const fetchSubscriptionPlans = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setSubscriptionLoading());
    const response = await getSubscriptionPlans();

    dispatch(setSubscriptionPlansSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchSubscription = (): AppThunk => async (dispatch) => {
  try {
    const response = await getSubscription();

    dispatch(setSubscriptionSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
};

export const addSubscription = (subscriptionId: string): AppThunk => async (dispatch) => {
  try {
    const response = await postSubscription(subscriptionId);

    dispatch(setSubscriptionSuccess(response.data.data));
  } catch (err) {
    console.log(err);
  }
};
