import React from "react";
import { Button, Menu, MenuItem, MenuDivider } from "@blueprintjs/core";
import { IntegrationsDrawer } from "../user/integrations/IntegrationsDrawer";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ProfileDrawer } from "../user/profile/ProfileDrawer";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";

export function UserMenu() {
  const [isIntegrationsOpen, setIntegrationsOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const { loginWithRedirect, logout, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Popover2
        content={
          <Menu>
            <MenuItem icon="person" text="Profile" onClick={() => setProfileOpen(!isProfileOpen)} />
            <MenuItem icon="settings" text="Settings" />
            <MenuItem icon="cog" text="Integrations" onClick={() => setIntegrationsOpen(!isIntegrationsOpen)} />
            <MenuDivider />
            {!isAuthenticated && <MenuItem icon="log-in" text="Sign In" onClick={() => loginWithRedirect()} />}
            {isAuthenticated && <MenuItem icon="log-out" text="Sign Out" onClick={() => logout({ returnTo: window.location.origin })} />}
          </Menu>
        }
        placement={"bottom-start"}
      >
        <Tooltip2 content="User Options">
          <Button icon="user" minimal={true}></Button>
        </Tooltip2>
      </Popover2>
      <IntegrationsDrawer isOpen={isIntegrationsOpen} onClose={() => setIntegrationsOpen(false)} />
      <ProfileDrawer isOpen={isProfileOpen} onClose={() => setProfileOpen(false)} />
    </>
  );
}

export default UserMenu;
