import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { getWallet } from "../nexusApi";
import { IWalletItem } from "../models/IWalletItem";

const walletSlice = createSlice({
  name: "wallet",
  initialState: [] as IWalletItem[],
  reducers: {
    getWalletSuccess(state, action: PayloadAction<IWalletItem[]>) {
      action.payload.forEach(({ exchange: marketplace, availableBalance }) => {
        const item = state.find((item) => item.exchange.id === marketplace.id);

        if (item) {
          item.availableBalance = availableBalance;
        } else {
          state.push({ exchange: marketplace, availableBalance });
        }
      });
    },
    getWalletFailure(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
  },
});

export const { getWalletSuccess, getWalletFailure: getWalletFailed } = walletSlice.actions;

export default walletSlice.reducer;

export const fetchWallet = (): AppThunk => async (dispatch) => {
  try {
    const response = await getWallet();
    dispatch(getWalletSuccess(response.data.data));
  } catch (err) {
    getWalletFailed(err.toString());
  }
};
